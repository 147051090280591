<template>
  <div class="content-container" v-show="improveProfileVisible" @click="close">
		<div class="d-flex justify-content-center align-items-center position-absolute content-position">
			<div class="card text-center p-4 shadow" style="max-width: 400px;">
				<button class="close-btn" @click="close">
					<i class="fa-regular fa-x"></i>
				</button>
				<div class="position-relative d-flex justify-content-center mb-3">
					<!-- Circular Profile Image -->
					<div class="position-relative">
						<img
							:src="profileImage"
							alt="Profile Picture"
							class="base-image"
							style="width: 270px; height: 240px;"
						/>
						<img
							v-if="profilePictureUrl"
							:src="profilePictureUrl"
							alt="User Profile Picture"
							class="profile-image"
							@error="handleImageError"
						/>

						<div class="position-absolute translate-middle badge rounded-circle bg-primary">
							<span class="text-white">{{ completionPercentage }}%</span>
						</div>
					</div>
				</div>

				<!-- Title -->
				<h5 class="mb-2 title">Add the Final Touch</h5>

				<!-- Description -->
				<p class="text-muted mb-4 description">
					Your profile is {{ completionPercentage }}% complete. Finish now to unlock full
					access to top-tier job opportunities.
				</p>

				<!-- Button -->
				<button class="btn btn-primary w-custom" @click="goToCv">
					Complete profile
				</button>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useGlobalStore } from '@/store/store';
import { useRouter} from 'vue-router';
import { useSkillsStore } from '@/store/skills';
import { handleImageError } from '@/store/utils';
import { useProfileStore } from '@/store/profile';

const	profileImage = ref(new URL('@/assets/improve_profile.png', import.meta.url).href)
const { improveProfileVisible, personalInfo, experiences, education, passion, selectedValues, ambitionFormCompleted, isAuthenticated } = storeToRefs(useGlobalStore())
const { isSkillsVerified, areSkillImproved } = storeToRefs(useSkillsStore())
const router = useRouter()
const { profilePictureUrl } = storeToRefs(useProfileStore())

const generalProfileImage = ref(new URL('@/assets/general_profile.png', import.meta.url).href)

const close = () => {
	improveProfileVisible.value = false
}

const goToCv = () => {
	router.push('/cv')
	improveProfileVisible.value = false
}

const completionPercentage = computed(() => {
	const defaultPassion = 'Passionate about extreme ironing, competitive cheese rolling, and underwater basket weaving. Avid collector of novelty rubber ducks and an enthusiastic participant in llama yoga. When not perfecting my skills in interpretive dance with houseplants, I enjoy hosting dinosaur-themed tea parties and volunteering as a professional hide-and-seek champion at local libraries. Also, a proud member of the international jellybean flavor taste-testing committee.'
	const personalPercentage = 5
	const experiencePercentage = 25
	const educationPercentage = 25
	const skillsPercentage = 12.5
	const ambitionsPercentage = 12.5
	const improveSkills = 5
	const valuesPercentage = 5
	const passionPercentage = 5
	const summaryPercentage = 5

	let total = 0

	// if (!isAuthenticated.value) return total;

	if (personalInfo.value?.summary) total += summaryPercentage;
	if (isSkillsVerified.value) total += skillsPercentage;
	if (ambitionFormCompleted.value) total += ambitionsPercentage;
	if (passion.value && passion.value !== defaultPassion) total += passionPercentage;
	if (selectedValues.value) total += valuesPercentage;
	if (education.value) total += educationPercentage;
	if (experiences.value) total += experiencePercentage;
	if (
		!Object.entries(personalInfo.value).some(([key, value]) => value === "")
	)
		total += personalPercentage;
	if (areSkillImproved) total += improveSkills;

	return total;
})
</script>

<style scoped>
.card {
  border-radius: 1rem;
}

.badge {
  font-size: 0.8rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
	top: 25%;
	left: 80%;
	padding: 25px;
	z-index: 1;
}

button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.title {
	font-size: 26px;
	font-family: 'Gilmer';
}

.description {
	color: #525864;
}

.title,
.description {
	text-align: center;
}

.w-custom {
	width: fit-content;
	margin: 0 auto;
}

.content-container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2000;
	width: 100%;
	height: 100%;
	background-color: rgba(5, 108, 242, .6);
}

.close-btn {
	position: absolute;
	top: 20px;
	right: 20px;
	background: none;
	border: none;
	font-size: 16px;
	line-height: 1;
	cursor: pointer;
	color: #7D8AA6;
	z-index: 1;
}

.content-position {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.base-image {
  position: relative;
  z-index: 1;
}

.profile-image {
  position: absolute;
  width: 75px;
  height: 75px;
  top: 80px;
  left: 117px;
  z-index: 2;
	border-radius: 50%;
}

.overlay-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
</style>