import apiClient from '@/axios.js';
import { publicApiClient } from '@/axios';
import axios from 'axios';
import { auth } from '@/firebase';
import * as type from '@/types/';
import { defineStore } from 'pinia';
import { ref, computed, reactive } from 'vue';
import { useSoftwareStore } from './software';
import { useSkillsStore } from './skills';
import { useGlobalStore } from './store';
import { useCookie } from '@/composables/useCookie';

axios.defaults.withCredentials = true;
export const useVacanciesStore = defineStore('vacancies', () => {

	const vacancies = ref<type.Vacancy[]>([])
	const vacanciesIds = ref<string[]>([])
	const bookmarkedVacancies = ref<type.Vacancy[]>([])
	const bookmarkedVacanciesIds = ref<string[]>([])
	const ignoredVacanciesIds = ref<string[]>([])
	const seenVacanciesIds = ref<string[]>([])
	const loading = ref(false)
	const firstFiveVacancyIds = ref<string[]>([])
	const firstFiveVacanciesSet = ref(false)
	const selectedVacancyId = ref<string>('')
	const vacanciesByPage = ref<{ [key: string | number]: any }>([])
	const filters = ref({})
	const currentPage = ref(0)
	const totalJobsAvailableByFilter = ref<{ [key: string | number]: any }>([])
	const totalVacancies = ref<number>(0)
	const totaEmployer = ref<number>(0)
	const newVacanciesThisWeek = ref<number>(0)

	const { skills } = useSkillsStore();
	const { softwarePackages } = useSoftwareStore();
	const { totalExperienceYears } = useGlobalStore();
	const { getCookie, updateCookie } = useCookie()

	const bookmarkedVacanciesCount = computed(() => bookmarkedVacancies.value.length);
	const ignoredVacanciesCount = computed(() => ignoredVacanciesIds.value.length);
	const isAuthenticated = computed(() => auth.currentUser !== null)

	const userTracking = getCookie('userTracking');

	const setTotalJobsAvailable = (filtersKey: string, totalJobsAvailable: number) => {
		if (JSON.stringify(filtersKey) !== '"{}"') totalJobsAvailableByFilter.value[filtersKey] = totalJobsAvailable
		else totalJobsAvailableByFilter.value[currentPage.value] = totalJobsAvailable
	}

	const setFilters = (newFilters: Record<string, unknown>) => {
		filters.value = newFilters;
	}

	const setCurrentPage = (pageNumber: number) => {
		currentPage.value = pageNumber;
	}

	const setVacanciesByPage = (pageNumber: number, filtersKey: string, newVacancies: type.Vacancy[]) => {
		if (JSON.stringify(filtersKey) === '"{}"') {
			vacanciesByPage.value[pageNumber] = newVacancies
		} else {
			vacanciesByPage.value[filtersKey] = {
				[pageNumber]: newVacancies,
			};
		}
	}

	const setVacancies = (newVacancies: type.Vacancy[]) => {
		vacancies.value = newVacancies;
		vacanciesIds.value = newVacancies.map((v) => v.id);
	}

	const appendVacancies = (newVacancies: type.Vacancy[]) => {
		vacancies.value = [...vacancies.value, ...newVacancies];
		vacanciesIds.value = [...vacanciesIds.value, ...newVacancies.map((v) => v.id)];
	}

	const saveFirstFiveVacancyIds = (newVacancies: type.Vacancy[]) => {
		if (!firstFiveVacanciesSet.value) {
			firstFiveVacancyIds.value = newVacancies.slice(0, 5).map((v) => v.id);
			firstFiveVacanciesSet.value = true;
		}
	}

	const addBookmarkedVacancy = (vacancy: type.Vacancy) => {
		if(!isAuthenticated.value) {
			let bookmarkedJobsStorage: any = localStorage.getItem('bookmarkedJobsStorage');
			
			if (!bookmarkedJobsStorage) {
				bookmarkedJobsStorage = JSON.stringify({ bookmarkedJobsIds: [], bookmarkedJobs: [] });
				localStorage.setItem('bookmarkedJobsStorage', bookmarkedJobsStorage);
			}
			
			// Parse the storage data
			bookmarkedJobsStorage = JSON.parse(bookmarkedJobsStorage);

			const { bookmarkedJobsIds = [], bookmarkedJobs = [] } = bookmarkedJobsStorage;

			if (!bookmarkedJobsIds.includes(vacancy.id)) {
				bookmarkedJobsIds.push(vacancy.id);
				bookmarkedJobs.push(vacancy);

				bookmarkedJobsStorage.bookmarkedJobsIds = bookmarkedJobsIds;
				bookmarkedJobsStorage.bookmarkedJobs = bookmarkedJobs;

				localStorage.setItem('bookmarkedJobsStorage', JSON.stringify(bookmarkedJobsStorage));
			}
		} else {
			if (!bookmarkedVacancies.value.find((v) => v.id === vacancy.id)) {
				bookmarkedVacancies.value.push(vacancy);
				bookmarkedVacanciesIds.value.push(vacancy.id);
			}
		}
	}

	const removeBookmarkedVacancy = (vacancyId: string) => {
		if(!isAuthenticated.value) {
			const bookmarkedJobsStorage = JSON.parse(JSON.stringify(localStorage.getItem('bookmarkedJobsStorage'))) || null
			if(bookmarkedJobsStorage) {
				const ids = bookmarkedJobsStorage.bookmarkedJobsIds || []
				if(ids.includes(vacancyId)) {
					bookmarkedJobsStorage.bookmarkedJobsIds = ids.filter((id: string) => id !== vacancyId)
					bookmarkedJobsStorage.bookmarkedJobs = ids.filter((v: type.Vacancy) => v.id !== vacancyId)
				}
			}
		} else {	
			bookmarkedVacancies.value = bookmarkedVacancies.value.filter((v) => v.id !== vacancyId);
			bookmarkedVacanciesIds.value = bookmarkedVacanciesIds.value.filter((id) => id !== vacancyId);
		}
	}

	const addIgnoredVacancy = (vacancyId: string) => {
		if (!ignoredVacanciesIds.value.includes(vacancyId)) {
			ignoredVacanciesIds.value.push(vacancyId);
		}
	}

	function addSeenVacancy(vacancyId: string) {
		if (!seenVacanciesIds.value.includes(vacancyId)) {
			seenVacanciesIds.value.push(vacancyId);
		}
	}
	const setIgnoredVacancies = (ids: string[]) => {
		ignoredVacanciesIds.value = ids || []
	}
	const setBookmarkedVacancies = (bookmarks: type.Vacancy[]) => {
		// console.log('Mutation - SET_BOOKMARKED_VACANCIES - bookmarkedVacancies:', bookmarkedVacancies);
		bookmarkedVacancies.value = bookmarks || [];
		bookmarkedVacanciesIds.value = bookmarks.map(v => v.id) || [];
	}
	const addToBookmarkedVacancies = async (vacancyId: string) => {
		const vacancy = vacancies.value.find((v) => v.id === vacancyId);
		if (vacancy) {
			addBookmarkedVacancy(vacancy);
		} else {
			const response = await fetchSingleVacancy(vacancyId) 
			if (response) {
				addBookmarkedVacancy(response);
			} else {
				console.warn('Unexpected response format:', response);
			}
		}
	}
	const addToIgnoredVacancies = (vacancyId: string) => {
		ignoredVacanciesIds.value = ignoredVacanciesIds.value || [];
		if (!ignoredVacanciesIds.value.includes(vacancyId)) {
			ignoredVacanciesIds.value.push(vacancyId);
		}
	}
	const removeVacancy = (vacancyId: string) => {
		vacancies.value = vacancies.value || [];
		vacanciesIds.value = vacanciesIds.value || [];
		vacancies.value = vacancies.value.filter((v: type.Vacancy) => v.id !== vacancyId);
		vacanciesIds.value = vacanciesIds.value.filter((id: string) => id !== vacancyId);
	}
	const removeVacancyBookmark = (vacancyId: string) => {
		if(!isAuthenticated.value) {
			const bookmarkedJobsStorage = localStorage.getItem('bookmarkedJobsStorage') ? JSON.parse(JSON.stringify(localStorage.getItem('bookmarkedJobsStorage'))) : null
			if(bookmarkedJobsStorage) {
				const storage = JSON.parse(bookmarkedJobsStorage)

				storage.bookmarkedJobsIds = storage.bookmarkedJobsIds.filter((id: string) => id !== vacancyId)
				storage.bookmarkedJobs = storage.bookmarkedJobs.filter((v: type.Vacancy) => v.id !== vacancyId)
				localStorage.setItem('bookmarkedJobsStorage', JSON.stringify(storage))
			}
		} else {
			bookmarkedVacancies.value = bookmarkedVacancies.value.filter((v: type.Vacancy) => v.id !== vacancyId);
			bookmarkedVacanciesIds.value = bookmarkedVacanciesIds.value.filter((id: string) => id !== vacancyId);
		}

	}

	const setLoading = (isLoading: boolean) => {
		loading.value = isLoading;
	}

	function setVacanciesState(payload: Record<string, unknown>) {
		for (const key in payload) {
			if (Object.prototype.hasOwnProperty.call(payload, key)) {
				const value = payload[key];
				if (value !== undefined && value !== null) {
					switch (key) {
						case 'vacancies':
							vacancies.value = value as type.Vacancy[];
							break;
						case 'vacanciesIds':
							vacanciesIds.value = value as string[];
							break;
						case 'bookmarkedVacancies':
							bookmarkedVacancies.value = value as type.Vacancy[];
							break;
						case 'bookmarkedVacanciesIds':
							bookmarkedVacanciesIds.value = value as string[];
							break;
						case 'ignoredVacanciesIds':
							ignoredVacanciesIds.value = value as string[];
							break;
						case 'seenVacanciesIds':
							seenVacanciesIds.value = value as string[];
							break;
						case 'loading':
							loading.value = value as boolean;
							break;
						case 'firstFiveVacancyIds':
							firstFiveVacancyIds.value = value as string[];
							break;
						case 'firstFiveVacanciesSet':
							firstFiveVacanciesSet.value = value as boolean;
							break;
						case 'selectedVacancyId':
							selectedVacancyId.value = value as string;
							break;
						case 'vacanciesByPage':
							vacanciesByPage.value = value as { [key: string | number]: any };
							break;
						case 'filters':
							filters.value = value as Record<string, unknown>;
							break;
						case 'currentPage':
							currentPage.value = value as number;
							break;
						case 'totalJobsAvailableByFilter':
							totalJobsAvailableByFilter.value = value as { [key: string | number]: any };
							break;
						default:
							console.warn(`Unhandled key: ${key}`);

					}
				}
			}
		}
	}

	async function fetchSingleVacancy(vacancyId: string) {
		try {
			let api = null;

			if(!isAuthenticated.value) api = publicApiClient;
			else api = apiClient;

			if(api === null) return

			const response = await api.get(`/jobs/${vacancyId}`);

			if (response.data && response.data.job) {
				return response.data.job[0];
			} else {
				console.warn('Unexpected response format:', response);
			}
		} catch (error) {
			console.error('Error fetching single vacancy:', error);
		}
	}

	async function fetchVacancies(payload: Record<string, unknown>) {
		const { pageNumber = 0, filters = {}, searchQuery = {} } = payload;
		const filtersKey = JSON.stringify(filters);

		// if (
		// 	filtersKey &&
		// 	vacanciesByPage.value[filtersKey] &&
		// 	vacanciesByPage.value[filtersKey][pageNumber as number]
		// ) {
		// 	const cachedVacancies = vacanciesByPage.value[filtersKey][pageNumber as number];
		// 	console.log('Using cached vacancies:', cachedVacancies);
		// 	setVacancies(cachedVacancies);

		// 	const cachedTotalJobs = totalJobsAvailableByFilter.value[filtersKey];
		// 	if (cachedTotalJobs !== undefined) {
		// 		setTotalJobsAvailable(filtersKey, cachedTotalJobs);
		// 	}

		// 	return;
		// }

		try {
			setLoading(true);
			let response;

			if (isAuthenticated.value) {
				const candidateSkills = skills;
				const candidateSoftwareSkills = softwarePackages;
				const ignoredVacancies = [...ignoredVacanciesIds.value, ...bookmarkedVacanciesIds.value];

				response = await apiClient.post('/search_enriched', {
					ignoredVacanciesIds: ignoredVacancies,
					skills: candidateSkills,
					software: candidateSoftwareSkills,
					totalExperienceYears,
					pageNumber,
					filters,
					searchQuery,
				});
			} else {
				response = await publicApiClient.post('/recent_vacancies', {
					pageNumber,
					filters,
				});
			}

			if (response.data && response.data.vacancies) {
				const newVacancies = response.data.vacancies;
				const totalJobsAvailable = response.data.totalJobsAvailable;
				console.log("this is the total jobs", totalJobsAvailable)

				setVacanciesByPage(pageNumber as number, filtersKey, newVacancies);
				setVacancies(newVacancies);
				setTotalJobsAvailable(filtersKey, totalJobsAvailable);

				if (pageNumber === 0) {
					saveFirstFiveVacancyIds(newVacancies);
				}
			} else {
				console.warn('Unexpected response format:', response.data);
			}
		} catch (error) {
			console.error('Error fetching vacancies:', error);
		} finally {
			setLoading(false);
		}
	}

	async function fetchVacanciesWithoutLoading(payload: Record<string, number>) {
		const { pageNumber = 0, filters = {}, searchQuery = {} } = payload;
		const filtersKey = JSON.stringify(filters);

		if (
			filtersKey !== '"{}"' &&
			vacanciesByPage.value[filtersKey] &&
			vacanciesByPage.value[filtersKey][pageNumber as number]
		) {
			const cachedVacancies = vacanciesByPage.value[filtersKey][pageNumber as number];
			console.log('Using cached vacancies:', cachedVacancies);
			setVacancies(cachedVacancies);

			const cachedTotalJobs = totalJobsAvailableByFilter.value[filtersKey];
			if (cachedTotalJobs !== undefined) {
				setTotalJobsAvailable(filtersKey, cachedTotalJobs);
			}

			return;
		} else if (vacanciesByPage.value[pageNumber as number]) {
			const cachedVacancies = vacanciesByPage.value[pageNumber as number];
			console.log('Using cached vacancies:', cachedVacancies);

			const cachedTotalJobs = totalJobsAvailableByFilter.value[pageNumber as number];
			console.log('Using cached total jobs:', cachedTotalJobs, totalJobsAvailableByFilter.value);
			if (cachedTotalJobs !== undefined) {
				setTotalJobsAvailable(filtersKey, cachedTotalJobs);
			}

			return;
		}

		try {
			let response;

			if (isAuthenticated.value) {
				const candidateSkills = skills;
				const candidateSoftwareSkills = softwarePackages;
				const ignoredVacancies = [...ignoredVacanciesIds.value, ...bookmarkedVacanciesIds.value];

				response = await apiClient.post('/search_enriched', {
					ignoredVacanciesIds: ignoredVacancies,
					skills: candidateSkills,
					software: candidateSoftwareSkills,
					totalExperienceYears,
					pageNumber,
					filters,
					searchQuery,
				});
			} else {
				response = await publicApiClient.post('/recent_vacancies', {
					pageNumber,
					filters,
				});
			}

			if (response.data && response.data.vacancies) {
				const newVacancies = response.data.vacancies;
				const totalJobsAvailable = response.data.totalJobsAvailable;

				setVacanciesByPage(pageNumber, filtersKey, newVacancies);
				setVacancies(newVacancies);
				setTotalJobsAvailable(filtersKey, totalJobsAvailable);

				if (pageNumber === 0) {
					saveFirstFiveVacancyIds(newVacancies);
				}
			} else {
				console.warn('Unexpected response format:', response.data);
			}
		} catch (error) {
			console.error('Error fetching vacancies:', error);
		}
	}



	const fetchBookmarkedVacancies = async ({ filters = {} } = {}) => {
		// Check if bookmarked vacancies are already loaded
		// if (state.bookmarkedVacancies && state.bookmarkedVacancies.length > 0) {
		//   console.log('Bookmarked vacancies already loaded.');
		//   return;
		// }

		try {
			setLoading(true);

			// Ensure that `bookmarkedVacanciesIds` are available
			// If not, you might need to fetch them from the server or initialize them
			bookmarkedVacanciesIds.value = bookmarkedVacanciesIds.value || [];

			if (bookmarkedVacanciesIds.value.length === 0) {
				console.log('No bookmarked vacancies IDs available.');
				return;
			}

			// Fetch the bookmarked vacancies from the API
			const response = await apiClient.post('/bookmarked_jobs', {
				bookmarkedVacanciesIds: bookmarkedVacanciesIds.value,
				filters,
			});

			if (response.data && response.data.jobs) {
				const internalVacancies = response.data.jobs;

				// Commit the vacancies to the store
				setBookmarkedVacancies(internalVacancies);
			} else {
				console.warn('Unexpected response format:', response.data);
			}
		} catch (error) {
			console.error('Error fetching bookmarked vacancies:', error);
		} finally {
			setLoading(false);
		}
	}


	const checkAmbitionAndLoadMore = async (payload: Record<string, unknown>) => {
		if (vacancies.value.length <= 5) {
			// const ambitionCompleted = await checkAmbitionForm(); // You'll need to implement this action
			// if (!ambitionCompleted) {
			// 	return;
			// }
		}

		// If ambition form is completed or not needed, load more vacancies
		await fetchVacancies({ ...payload, pageNumber: currentPage.value + 1 });
	}

	const fetchCounterData = () => {
		publicApiClient.get('/vacancy_statistics').then((response) => {
			if (response.data) {
				totalVacancies.value = response.data.totalVacancies;
				totaEmployer.value = response.data.totalEmployers;
				newVacanciesThisWeek.value = response.data.countLast7Days;
			}
		})
	}

	return {
		vacancies,
		vacanciesIds,
		bookmarkedVacancies,
		bookmarkedVacanciesIds,
		ignoredVacanciesIds,
		seenVacanciesIds,
		loading,
		firstFiveVacancyIds,
		firstFiveVacanciesSet,
		selectedVacancyId,
		vacanciesByPage,
		filters,
		currentPage,
		totalJobsAvailableByFilter,
		bookmarkedVacanciesCount,
		ignoredVacanciesCount,
		totalVacancies,
		totaEmployer,
		newVacanciesThisWeek,
		setTotalJobsAvailable,
		setFilters,
		setCurrentPage,
		setVacanciesByPage,
		setVacancies,
		appendVacancies,
		saveFirstFiveVacancyIds,
		addBookmarkedVacancy,
		removeBookmarkedVacancy,
		addIgnoredVacancy,
		addSeenVacancy,
		setIgnoredVacancies,
		setBookmarkedVacancies,
		addToBookmarkedVacancies,
		addToIgnoredVacancies,
		removeVacancy,
		removeVacancyBookmark,
		setLoading,
		setVacanciesState,
		fetchVacancies,
		fetchVacanciesWithoutLoading,
		fetchBookmarkedVacancies,
		checkAmbitionAndLoadMore,
		fetchSingleVacancy,
		fetchCounterData
	}
})