<template>
	<div v-show="isPopupVisible" class="modal-overlay" @click="closePopup">
		<div class="position-relative" :class="[isMobile ? 'modal-content-mobile' : 'modal-content', modalClasses]" @click.stop>
			<div class="d-flex justify-content-center align-items-center flex-column">
				<!-- Desktop -->
				<div v-if="!isMobile" class="d-flex justify-content-center align-items-center flex-column">
					<button class="close-btn" @click="closePopup">
						<i class="fa-regular fa-x"></i>
					</button>
					<img v-if="isImageVisible" class="w-60" alt="popup-img" src="@/assets/images/careerGNew.png" />
					<div class="d-flex justify-content-center align-items-center flex-column px-4">
						<div v-if="subtitleText" class="subtitle text-center" :class="subtitleClasses" v-html="subtitleText"></div>
						<p v-if="popupText" class="text-center grey-text" :class="textClasses" v-html="popupText"></p>
						<input type="email" v-model="contactEmail" placeholder="Your e-mail address"
							style="width: auto; padding: 10px; border-radius: 5px; border: 1px solid #ccc; margin-top: 10px; margin-bottom: 10px;"
							v-if="emailInput" :class="inputClasses" />

						<div class="input-group mt-2" v-if="registerPopup">
							<input type="email" v-model="registerEmailPopup" class="input-field-mobile"
								placeholder="Your e-mail address" />
						</div>
						<div class="input-group my-2" v-if="registerPopup">
							<input :type="showPassword ? 'text' : 'password'" id="password-register" name="password"
								v-model="registerPasswordPopup" placeholder="Password" required class="input-field-mobile" />
							<span @click="togglePasswordVisibility" class="password-toggle-icon">
								<i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
							</span>
						</div>

						<div class="input-group" v-if="registerPopup">
							<input :type="showConfirmPassword ? 'text' : 'password'" id="confirm-password-register"
								name="confirmPassword" v-model="confirmPasswordPopup" placeholder="Confirm Password" required
								class="input-field-mobile" />
							<span @click="toggleConfirmPasswordVisibility" class="password-toggle-icon">
								<i :class="showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
							</span>
						</div>
						<button v-if="!auth.currentUser || isButtonShown" class="button" :class="buttonClasses" @click="buttonFn">{{
							textButton }}</button>
						<p v-if="secondaryPopupText" class="grey-text" :class="secondaryTextClasses" v-html="secondaryPopupText"></p>
					</div>
				</div>

				<!-- Mobile -->
				<div v-else class="d-flex justify-content-center align-items-center flex-column">
					<button class="close-btn" @click="closePopup">
						<i class="fa-regular fa-x"></i>
					</button>
					<div v-if="isImageVisible">
						<img class="h-100 w-100" alt="popup-img" src="@/assets/images/careerGNew.png" />
					</div>
					
					<div v-if="subtitleText" class="subtitle text-center" :class="subtitleClasses" v-html="subtitleText"></div>
					<p v-if="popupText" class="grey-text" v-html="popupText" :class="textClasses"></p>
					<input type="email" v-model="contactEmail" placeholder="Your e-mail address"
						style="width: auto; padding: 10px; border-radius: 5px; border: 1px solid #ccc; margin-top: 10px; margin-bottom: 10px;"
						v-if="emailInput" :class="inputClasses" />

					<div class="input-group mt-2" v-if="registerPopup">
						<input type="email" v-model="registerEmailPopup" class="input-field-mobile" placeholder="Your e-mail address" />
					</div>
					<div class="input-group my-2" v-if="registerPopup">
						<input :type="showPassword ? 'text' : 'password'" id="password-register" name="password"
							v-model="registerPasswordPopup" placeholder="Password" required class="input-field-mobile" />
						<span @click="togglePasswordVisibility" class="password-toggle-icon">
							<i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
						</span>
					</div>

					<div class="input-group" v-if="registerPopup">
						<input :type="showConfirmPassword ? 'text' : 'password'" id="confirm-password-register"
							name="confirmPassword" v-model="confirmPasswordPopup" placeholder="Confirm Password" required
							class="input-field-mobile" />
						<span @click="toggleConfirmPasswordVisibility" class="password-toggle-icon">
							<i :class="showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
						</span>
					</div>

					<button v-if="!auth.currentUser || isButtonShown" class="button" :class="buttonClasses" @click="buttonFn">{{
						textButton }}</button>

					<p class="py-4 grey-text" :class="secondaryTextClasses" v-if="secondaryPopupText" v-html="secondaryPopupText"></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { usePopup } from '../../composables/usePopup';
import { auth } from "@/firebase"
import { useDetectMobileDevice } from '../../composables/useDetectMobileDevice';
import { ref } from 'vue';

const { isPopupVisible,
	popupText,
	secondaryPopupText,
	textButton,
	buttonFn,
	isButtonShown,
	isImageVisible,
	registerPopup,
	emailInput,
	closePopup,
	modalClasses,
	textClasses,
	secondaryTextClasses,
	buttonClasses,
	inputClasses,
	registerEmailPopup,
	registerPasswordPopup,
	confirmPasswordPopup,
	subtitleClasses,
	subtitleText
} = usePopup()

const { isMobile } = useDetectMobileDevice()
const contactEmail = ref('')
const showPassword = ref(false)
const showConfirmPassword = ref(false)
const togglePasswordVisibility = () => showPassword.value = !showPassword.value
const toggleConfirmPasswordVisibility = () => showConfirmPassword.value = !showConfirmPassword.value

</script>

<style scoped>
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(5, 108, 242, .6);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 4000;
}

.input-group {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
}

.input-field-mobile {
	box-sizing: border-box;
	width: 100%;
	height: 39px;
	padding: 10px;
	background: #F6FAFF;
	border: 1px solid #CED4E0;
	border-radius: 4px;
	font-family: 'Inter-Regular', sans-serif;
	font-weight: 300;
	font-size: 14px;
	line-height: 160%;
	color: #7D8AA6;
	text-align: center;
	margin: 0px;
	position: relative;
}

.password-toggle-icon {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
}

.logo-text {
	font-weight: 500;
	line-height: normal;
	color: #000;
	font-family: "Gilmer-Regular", Helvetica, sans-serif;
	font-size: 30.5px;
}

.logo-text-mobile {
	font-family: "Gilmer-Regular", Helvetica, sans-serif;
	font-weight: 500;
	line-height: normal;
	color: #000;
	font-size: 20px;
}

.modal-content {
	background-color: white;
	padding: 20px 20px 30px 20px;
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 56, 130, .05);
	max-width: 40%;
}

.modal-content-mobile {
	background-color: white;
	padding: 25px;
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 56, 130, .05);
	max-width: 85%;
	max-height: 75%;
	overflow-y: auto;
}

.subtitle{
	font-size: 18px;
	font-family: 'Gilmer';
	font-weight: bold;
	margin-bottom: 10px;
}

.img {
	height: 60px;
	width: auto;
}

.w-40 {
	width: 40%;
}

.button {
	box-sizing: border-box;
	margin: 20px 0;
	height: 39px;
	padding: 10px 20px;
	background: #056cf2;
	border-radius: 4px;
	font-family: "Inter", sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 160%;
	color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 0px;
}

.text-wrapper {
	color: #0d0e10;
	font-family: "Gilmer-Medium", Helvetica, sans-serif;
	font-size: 30.5px;
	font-weight: 500;
	line-height: normal;
}

.group {
	display: flex;
	align-items: center;
	gap: 10px;
	/* Space between image and text */
	margin-bottom: 20px;
	/* Space below the group */
}

p {
	font-size: 14px;
}

.w-80 {
	width: 80%;
}

.w-60 {
	width: 60%;
}

.grey-text {
	color: #525864;
}

.close-btn {
	position: absolute;
	top: 20px;
	right: 20px;
	background: none;
	border: none;
	font-size: 16px;
	line-height: 1;
	cursor: pointer;
	color: #7D8AA6;
}

@media screen and (max-width: 600px) {
	.img {
		height: 35px;
		width: auto;
	}
}
</style>