import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.css';
import './main.css';
import 'nouislider/dist/nouislider.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { OhVueIcon, addIcons } from 'oh-vue-icons';
import { 
  MdDeleteoutline, FaUserCircle, LaGlobeEuropeSolid, BiWindowSidebar, 
  BiLinkedin, SiOpenai, BiPersonCircle, MdWorkRound, MdDataobjectOutlined, 
  MdSchoolRound, GiMeshNetwork, LaLaptopCodeSolid, GiProgression, 
  BiBarChartLineFill, GiBamboo, FaRoute, CoWeightlifitng 
} from 'oh-vue-icons/icons';
import apiClient from '@/axios';
import VueGtm from 'vue-gtm';
import { saveStatePlugin } from './store/plugins/saveStatePlugin';
import { useGlobalStore } from './store/store';
import { watch } from 'vue';
import * as Sentry from "@sentry/vue";

// Pre-initialize GTM configuration
const GTM_CONFIG = {
  id: 'GTM-KBBXH4ZP',
  vueRouter: router,
  enabled: true,
  debug: import.meta.env.DEV,
  loadScript: true,
  trackOnNextTick: false,
};

// Initialize app with optimized flow
try {
  // Initialize icons in parallel
  const iconPromise = Promise.resolve().then(() => {
    addIcons(
      MdDeleteoutline, FaUserCircle, LaGlobeEuropeSolid, BiWindowSidebar, 
      BiLinkedin, SiOpenai, BiPersonCircle, MdWorkRound, MdDataobjectOutlined, 
      MdSchoolRound, GiMeshNetwork, LaLaptopCodeSolid, GiProgression, 
      BiBarChartLineFill, GiBamboo, FaRoute, CoWeightlifitng
    );
  });

  const app = createApp(App);
  const pinia = createPinia();
  pinia.use(saveStatePlugin);
  app.use(pinia);
  app.component('v-icon', OhVueIcon);
  app.use(router);

  // Move GTM initialization here, after app is created
  const initializeGTM = () => {
    if (!window.Cookiebot?.consent?.statistics) return;
    app.use(VueGtm, GTM_CONFIG);
  };

  // Handle cookie consent
  const handleCookieConsent = () => {
    const onConsentChange = () => {
      window.Cookiebot?.consent?.statistics && initializeGTM();
    };

    if (document.cookie.includes('CookieConsent') && window.Cookiebot?.consent?.statistics) {
      initializeGTM();
      return;
    }

    window.addEventListener('CookiebotOnAccept', onConsentChange, { once: true });
  };

  // Handle cookie consent in parallel with app initialization
  queueMicrotask(handleCookieConsent);

  // Initialize Sentry only in production
  if (import.meta.env.PROD) {
    Sentry.init({
      app,
      dsn: "https://ce2c33e871f5d9bfa6a6972df798ced8@o4508448949272576.ingest.de.sentry.io/4508448955760720",
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }

  // Wait for login check and icons before mounting
  Promise.all([
    useGlobalStore().checkLogin(),
    iconPromise
  ]).then(() => {
    app.mount('#app');
  }).catch((error) => {
    console.error('Error during initialization:', error);
    app.mount('#app'); // Ensure the app mounts even on error
  });

} catch (error) {
  console.error('Critical initialization error:', error);
}
