import { ref, watchEffect } from "vue";
import { useRouter } from "vue-router";

const isPopupVisible = ref(false)
const subtitleText = ref('')
const popupText = ref('')
const secondaryPopupText = ref('')
const textButton = ref('')
const buttonFn = ref(() => { })
const isImageVisible = ref(true)
const isButtonShown = ref(false)
const emailInput = ref(false)
const modalClasses = ref('')
const subtitleClasses = ref('')
const textClasses = ref('')
const secondaryTextClasses = ref('')
const buttonClasses = ref('')
const inputClasses = ref('')
const registerPopup = ref(false)
const registerEmailPopup = ref('')
const registerPasswordPopup = ref('')
const confirmPasswordPopup = ref('')

export const usePopup = () => {
	const router = useRouter()

	const openPopup = () => {
		isPopupVisible.value = true
	}

	const closePopup = () => {
		isPopupVisible.value = false
	}

    /** obj: {popupText: String, secondaryText: String, textButton: string} */
    const setTexts = (obj: Record<string, string>) => {
				subtitleText.value = obj.subtitleText
        popupText.value = obj.popupText
        secondaryPopupText.value = obj.secondaryText
        textButton.value = obj.textButton
    }

	const setButtonFn = (fn: () => void) => {
		buttonFn.value = fn
	}

	const setClasses = (obj: Record<string, string>) => {
		modalClasses.value = obj.modalClasses
		textClasses.value = obj.textClasses
		secondaryTextClasses.value = obj.secondaryTextClasses
		buttonClasses.value = obj.buttonClasses
		inputClasses.value = obj.inputClasses
		subtitleClasses.value = obj.subtitleClasses
	}

	function resetClasses() {
		modalClasses.value = ''
		textClasses.value = ''
		secondaryTextClasses.value = ''
		buttonClasses.value = ''
		inputClasses.value = ''
		subtitleClasses.value = ''
	}

	watchEffect(() => {
		if (!popupText.value && !secondaryPopupText.value && !textButton.value) {
			setTexts({
				subtitleText: 'Unlock all opportunities',
				popupText: `
					Your profile gives you access to permanent opportunities. Log in for free to access all Lionhead services and matching jobs.
				`,
				// secondaryText: `As a Lionhead member, you stay anonymous, and your information is never shared without your consent, giving you control over your career without interference from recruiters who often lack the expertise to truly understand your unique skills and capabilities."`,
				textButton: `Create free account`
			})
			setClasses({
				buttonClasses: 'w-100',
			})
			registerPopup.value = true
			isButtonShown.value = true
			setButtonFn(() => {
				router.push({name: 'Register'})
				closePopup()
			})
		}
	})

	return {
		isPopupVisible,
		isImageVisible,
		subtitleText,
		popupText,
		secondaryPopupText,
		textButton,
		isButtonShown,
		emailInput,
		modalClasses,
		subtitleClasses,
		textClasses,
		secondaryTextClasses,
		buttonClasses,
		inputClasses,
		registerPopup,
		registerEmailPopup,
		registerPasswordPopup,
		confirmPasswordPopup,

		openPopup,
		closePopup,
		setTexts,
		setButtonFn,
		setClasses,
		resetClasses,
		buttonFn
	}
}

export default { usePopup }