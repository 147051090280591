import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const useFingerprint = () => {
	const generateFingerprint = async (): Promise<string> => {
		const fp = await FingerprintJS.load();
		const result = await fp.get();
		return result.visitorId; // A unique fingerprint ID
	};

	return {
		generateFingerprint
	}
}

export default { useFingerprint }
