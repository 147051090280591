<template>
    <!-- Outer container -->
    <div :class="layoutClass">
  
      <!-- Mobile Menu Transition -->
      <transition :name="menuTransition" @after-leave="performRouteChange">
        <Menu 
          v-if="isMenuVisible && isMobile" 
          @closeMenu="closeMenu" 
          @editProfile="handleProfileEdit" 
        />
      </transition>
      
      <!-- Header for Mobile -->
      <header 
        :class="['header', { 'header-hidden': !isHeaderVisible }]"
      >
        <div class="header-content">
          <button class="logo-button-mobile">
            <router-link to="/" class="logo-link-mobile">
              <img 
                class="logo-mobile" 
                alt="Group" 
                src="@/assets/lionhead-logo-symbol_blue.svg" 
              />
              <span class="logo-text-mobile">
                LionHead
                <div class="notification-beta">Beta</div>
              </span>
            </router-link>
          </button>
          <div 
            class="dropdown" 
            v-if="!['/cv-builder'].includes($route.path)"
          >
            <button 
              @click="toggleMenu()" 
              class="logo-button-mobile"
            >
              <NotificationBubble 
                v-if="isAuthenticated && bubbleNotifications && bubbleNotifications > 10" 
                :unseen-notification="9" 
                :plus-shown="true" 
                class="notification-circle" 
              />
              <NotificationBubble 
                v-else-if="isAuthenticated && bubbleNotifications && bubbleNotifications <= 9" 
                :unseen-notification="bubbleNotifications" 
                class="notification-circle" 
              />
              <img 
                v-if="profilePictureUrl" 
                class="profile-img" 
                :src="profilePictureUrl"
                @error="handleImageError"
                alt="profile image"
              />
              <img 
                v-else 
                class="profile-button" 
                alt="Profile" 
                :src="defaultImage" 
              />
            </button>
          </div>
        </div>
      </header>
  
      <!-- Main Content -->
      <main 
          id="content" 
          class="content"
          :class="{ 'no-header': !isHeaderVisible }"
        >
        <router-view v-slot="{ Component, route }">
          <transition :name="isMenuPage ? 'slide-fade' : 'none'">
            <span :key="route.name"> 
              <component
                :is="Component"
                @child-scroll="handleChildScroll"
                @update-unseen-count="updateUnseenVacanciesCount"
                :isHeaderVisible="isHeaderVisible"
              />
            </span>
          </transition>
        </router-view>
      </main>
  
      <!-- Footer for Mobile -->
      <footer 
        v-if="isMobile && !['/cv-builder'].includes($route.path)" 
        class="footer"
      >
        <div class="footer-content">
          <!-- CV Button -->
          <MenuButton
            v-if="isAuthenticated || profileState"
            property1="default"
            className="additional-class"
            svgSrc="CV.svg"
            altText="Inbox Icon"
            buttonText="My Profile"
            to="/cv"
            routeName="PreviewCV"
          />
          <MenuButton
            v-else
            property1="default"
            className="additional-class"
            svgSrc="CV.svg"
            altText="Inbox Icon"
            buttonText="My Profile"
            to="/profile-analysis"
            routeName="PreviewCV"
          />
  
          <!-- Bookmarks Button -->
          <MenuButton
            v-if="isAuthenticated || !userTracking?.maxLimitReached"
            property1="default"
            className="additional-class"
            svgSrc="bookmarks.svg"
            altText="Saved Icon"
            buttonText="Bookmarks"
            to="/bookmarks"
            routeName="Bookmarks"
          />
          <MenuButton
            v-else
            property1="default"
            className="additional-class"
            svgSrc="bookmarks.svg"
            altText="Saved Icon"
            buttonText="Bookmarks"
            :to="route.path"
            @click="openPopup"
            routeName="Bookmarks"
          />
  
          <!-- Match / Jobs Button -->
          <MenuButton
            v-if="!isAuthenticated && route.path == '/cv'"
            className="additional-class"
            svgSrc="lionhead-logo.svg"
            altText="lionhead Icon"
            buttonText="Match"
            to="/"
            @click="openPopup"
            routeName="Jobs"
            :notification="unseenVacanciesCount"
          />
          <MenuButton
            v-else
            className="additional-class"
            svgSrc="lionhead-logo.svg"
            altText="lionhead Icon"
            buttonText="Match"
            to="/"
            routeName="Jobs"
            :notification="unseenVacanciesCount"
          />
  
          <!-- Ambition Button -->
          <MenuButton
            className="additional-class"
            svgSrc="stairs.svg"
            altText="Ambition Icon"
            buttonText="Ambition"
            to="/ambition"
            routeName="AmbitionQuestionnair"
          />
        </div>
      </footer>
  
      <!-- Feedback Button -->
      <button class="feedback-button" @click="showFeedbackModal = true">
        <img src="@/assets/comment.svg" alt="Feedback" />
      </button>
  
      <!-- Feedback Modal -->
      <FeedbackModal
        :show="showFeedbackModal"
        @close="showFeedbackModal = false"
        :componentTitle="currentComponentTitle"
        :scrollPosition="scrollPosition"
      />
    </div>
  </template>
  
  <script setup>
  /* ----------------------------------------------------------------
    Imports
  ----------------------------------------------------------------- */
  import { ref, computed, onMounted, onBeforeUnmount, watch, onBeforeMount, nextTick } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import MenuButton from "@/components/CustomComponents/MenuButton.vue";
  import FeedbackModal from "@/components/ProfilePages/feedback.vue";
  import Menu from '@/components/ProfilePages/Menu.vue';
  import { throttle } from 'lodash';
  import { auth } from "@/firebase"
  import { useDetectMobileDevice } from '../../composables/useDetectMobileDevice';
  import { storeToRefs } from 'pinia';
  import { useGlobalStore } from '@/store/store';
  import { useProfileStore } from '@/store/profile';
  import { usePopup } from '@/composables/usePopup';
  import NotificationBubble from '../CustomComponents/NotificationBubble.vue';
  import { useCookie } from '@/composables/useCookie';
  import defaultImage from '@/assets/profile.png';
  import { handleImageError } from '@/store/utils';
  
  /* ----------------------------------------------------------------
    Define emits (if needed)
  ----------------------------------------------------------------- */
  defineEmits(['childScroll', 'updateUnseenCount']);
  
  /* ----------------------------------------------------------------
    Pinia Store References
  ----------------------------------------------------------------- */
  const { passion, isOverlayVisible, education, experiences, selectedValues, personalInfo, ambitionFormCompleted } = storeToRefs(useGlobalStore());
  const { profilePictureUrl } = storeToRefs(useProfileStore());
  const router = useRouter();
  const route = useRoute();
  
  
  /* ----------------------------------------------------------------
    Reactive References
  ----------------------------------------------------------------- */
  const isHeaderVisible = ref(true);
  const lastScrollTop = ref(0);
  const showFeedbackModal = ref(false);
  const isMenuVisible = ref(false);
  const scrollPosition = ref(0);
  const lastScrollDirection = ref('down');
  const unseenVacanciesCount = ref(0);
  let nextRoute = null;
  const counterScale = ref(1);
  const profileState = localStorage.getItem('profileState') 
    ? JSON.parse(localStorage.getItem('profileState')) 
    : null;
  const { isMobile, detectMobileDevice } = useDetectMobileDevice();
  const { getCookie } = useCookie();
  
  /* ----------------------------------------------------------------
    Computed
  ----------------------------------------------------------------- */
  const isMenuPage = computed(() => route.name === 'Menu');
  const currentComponentTitle = computed(() => route.name);
  const menuTransition = ref('slide-right');
  const userTracking = getCookie('userTracking');
  
  const { openPopup } = usePopup();
  
  /* 
    Check if user is authenticated
  */
  const isAuthenticated = computed(() => {
    return auth.currentUser !== null;
  });
  
  /* 
    Layout classes 
  */
  const layoutClass = computed(() => ({
    layout: true,
    // you can add more mobile-specific classes here if needed
  }));
  
  
  /*
    Calculate bubble notifications
  */
  import { useSkillsStore } from '@/store/skills';
  const { isSkillsVerified, areSkillImproved } = storeToRefs(useSkillsStore());
  const bubbleNotifications = computed(() => {
    const defualPassion = 'Passionate about extreme ironing ...'; // truncated for brevity
    let tot = 0;
    // Check for missing fields
    if(selectedValues.value.length === 0) tot += 1;
    if(Object.values(personalInfo.value).some((item) => item === '' || item === null)) {
      tot += Object.values(personalInfo.value)
        .filter((item) => item === '' || item === null).length;
    }
    if(!ambitionFormCompleted.value) tot += 9;
    if(!isSkillsVerified.value) tot += 1;
    if(!areSkillImproved.value) tot += 1;
    if(passion.value === defualPassion) tot += 1;
    if(!personalInfo.value.summary) tot += 1;
    if(!education.value.length) tot += 1;
    if(!experiences.value.length) tot += 1;
  
    return tot;
  });
  
  /* ----------------------------------------------------------------
    Menu Handlers
  ----------------------------------------------------------------- */
  const toggleMenu = () => {
    if (!isMenuVisible.value) {
      menuTransition.value = 'slide-right';
      isMenuVisible.value = true;
    } else {
      menuTransition.value = 'slide-right';
      isMenuVisible.value = false;
    }
  };
  
  const closeMenu = () => {
    menuTransition.value = 'slide-right';
    isMenuVisible.value = false;
  };
  
  const handleProfileEdit = () => {
    menuTransition.value = 'slide-left';
    isMenuVisible.value = false;
    nextRoute = { name: 'Personal' };
  };
  
  const performRouteChange = () => {
    if (nextRoute) {
      router.push(nextRoute);
      nextRoute = null;
    }
  };
  
  /* ----------------------------------------------------------------
    Scroll Handling
  ----------------------------------------------------------------- */
  const handleScroll = (event) => {
    const scrollTop = event?.target.scrollTop;
    const scrollHeight = event?.target.scrollHeight;
    const clientHeight = event?.target.clientHeight;
  
    if (scrollTop + clientHeight >= scrollHeight) {
      isHeaderVisible.value = false;
    } else {
      if (scrollTop > lastScrollTop.value) {
        if (lastScrollDirection.value !== 'down') {
          isHeaderVisible.value = false;
          lastScrollDirection.value = 'down';
        }
      } else {
        if (lastScrollDirection.value !== 'up') {
          isHeaderVisible.value = true;
          lastScrollDirection.value = 'up';
        }
      }
    }
  
    lastScrollTop.value = scrollTop <= 0 ? 0 : scrollTop;
    scrollPosition.value = scrollTop;
  };
  
  const throttledHandleScroll = throttle(handleScroll, 100);
  
  const handleChildScroll = (event) => {
    throttledHandleScroll(event);
  };
  

  
  /* ----------------------------------------------------------------
    Event Handler Filter
  ----------------------------------------------------------------- */
  function filterEventHandlers(params) {
    return Object.fromEntries(
      Object.entries(params || {}).filter(([key, value]) => {
        return typeof value === 'function';
      })
    );
  }
  
  /* ----------------------------------------------------------------
    Update unseen vacancies
  ----------------------------------------------------------------- */
  const updateUnseenVacanciesCount = (count) => {
    unseenVacanciesCount.value = count;
  };
  
  /* ----------------------------------------------------------------
    Lifecycle Hooks
  ----------------------------------------------------------------- */
  onBeforeMount(() => {
    detectMobileDevice();
  });
  
  onMounted(() => {
    const mainContent = document.querySelector('.content');
    if (mainContent) {
      mainContent.addEventListener('scroll', throttledHandleScroll);
    }
    const setVH = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Set initial value
    setVH();

    // Update on resize and orientation change
    window.addEventListener('resize', setVH);
    window.addEventListener('orientationchange', setVH);

    // Clean up
    onBeforeUnmount(() => {
      window.removeEventListener('resize', setVH);
      window.removeEventListener('orientationchange', setVH);
    });
  });
  
  onBeforeUnmount(() => {
    const mainContent = document.querySelector('.content');
    if (mainContent) {
      mainContent.removeEventListener('scroll', throttledHandleScroll);
    }
  });
  
  </script>
  
  <style scoped>
  /* Layout wrapper */
  .layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    background-color: #fff; 
    height: 100vh; 
    height: calc(var(--vh, 1vh) * 100);
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    overscroll-behavior: contain;
    overscroll-behavior-y: contain; /* For older browsers */
  }
  
  /* Header (mobile) */
  .header {
    flex: 0 0 auto;
    width: 100%;
    height: 45px;
    background: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    touch-action: manipulation;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transform: translateY(0);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
  }
  
  .header-hidden {
    transform: translateY(-100%);
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 15px;
  }
  
  /* Logo (mobile) */
  .logo-link-mobile {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  .logo-mobile {
    width: 35px;
    height: auto;
  }
  .logo-text-mobile {
    font-family: Gilmer;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #056CF2;
    margin-left: 5px;
    font-size: 20px;
  }
  .notification-beta {
    position: absolute;
    top: 0px;
    right: -28px;
    background-color: #ff832b;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 10px;
    font-weight: light;
    z-index: 100;
    padding: 2px 5px;
  }
  .logo-button-mobile {
    position: relative;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  /* Content container */
  .content {
  position: absolute;
  left: 0;
  right: 0;
  top: 45px;            /* default: header is shown */
  bottom: 60px;         /* space for footer */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  backface-visibility: hidden;
}

.content.no-header {
  top: 0;              /* no header => top = 0 */
}
  /* Footer (mobile) */
  .footer {
    flex: 0 0 auto;
    width: 100%;
    height: 60px;
    background: #056cf2;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    touch-action: manipulation;
  }
  .footer-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  }
  
  /* Notification Bubble */
  .notification-circle {
    position: absolute;
    top: -4px;
    left: -8px;
    height: 18px;
    width: 18px;
    background-color: #ff832b;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 10px;
    font-weight: light;
  }
  
  /* Profile images / placeholders */
  .profile-img {
    width: 30px;
    height: auto;
    fill: #056cf2;
    color: #056cf2;
    border-radius: 50%;
    border: 1px solid #d3d3d3;
  }
  .profile-button {
    width: 30px;
    height: auto;
    fill: #056cf2;
    color: #056cf2;
  }
  
  /* Feedback Button */
  .feedback-button {
    position: fixed;
    bottom: 70px;
    right: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #056cf2;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    z-index: 100;
  }
  .feedback-button img {
    width: 24px;
  }
  .feedback-button:hover {
    background-color: #045bb5;
  }
  
  /* Transition classes */
  .slide-right-enter-active,
  .slide-right-leave-active {
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  .slide-right-enter-from {
    transform: translateX(100%);
    opacity: 0;
  }
  .slide-right-enter-to {
    transform: translateX(0);
    opacity: 1;
  }
  .slide-right-leave-from {
    transform: translateX(0);
    opacity: 1;
  }
  .slide-right-leave-to {
    transform: translateX(100%);
    opacity: 0;
  }
  
  /* Slide-left (if needed for editing profile) */
  .slide-left-enter-active,
  .slide-left-leave-active {
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  .slide-left-enter-from {
    transform: translateX(100%);
    opacity: 0;
  }
  .slide-left-enter-to {
    transform: translateX(0);
    opacity: 1;
  }
  .slide-left-leave-from {
    transform: translateX(0);
    opacity: 1;
  }
  .slide-left-leave-to {
    transform: translateX(-100%);
    opacity: 0;
  }
  
  /* No transition */
  .none-enter-active,
  .none-leave-active {
    transition: none;
  }
  </style>