import { useGlobalStore } from "@/store/store";
import { storeToRefs } from "pinia";
import { ref } from "vue";

const isMobile = ref(false)

export const useDetectMobileDevice = () => {
	const { isMobileDevice } = storeToRefs(useGlobalStore());

	const detectMobileDevice = () => {
		const mediaQueryMatch = matchMedia('(hover: none), (pointer: coarse)').matches;
		const touchEventSupported = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
		const userAgentMatch = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		const isTouchLaptop = /Windows NT/.test(navigator.userAgent) && touchEventSupported;

		if (isTouchLaptop) {
			setMobileValue(false);
		} else {
			setMobileValue(userAgentMatch || (mediaQueryMatch && touchEventSupported));
		}

		return isMobile.value
	};

	const setMobileValue = (value: boolean) => {
		isMobile.value = value
		isMobileDevice.value = isMobile.value;
	}


	return {
		isMobile,

		detectMobileDevice
	}
}

export default {
	useDetectMobileDevice
}