<template>
	<div class="linear-background">
		<slot name="gradient_components">
		</slot>
	</div>
</template>

<script setup lang="ts">
</script>

<style scoped>
.linear-background {
	background: linear-gradient(66deg, rgba(5,108,242,1) 0%, rgba(3,62,140,1) 62%, rgba(3,62,140,1) 94%);
	padding-bottom: 40px;
	position: relative;
}
</style>