import { ref } from 'vue'
import { useRoute } from 'vue-router'
// @ts-ignore
import Warning from '@/assets/warning.svg'
// @ts-ignore
import Close from '@/assets/close.svg'
// @ts-ignore
import Exclamation from '@/assets/exclamation.svg'
// @ts-ignore
import Info from '@/assets/information.svg'
// @ts-ignore
import SuccessIcon from '@/assets/success_icon.png'
// @ts-ignore
import ErrorIcon from '@/assets/error_icon.png'

type CustomNotification = {
	text: string,
	svg: string,
	title?: string,
	buttonText?: string,
	action?: () => void
}

type NotificationTpe = 'success' | 'error' | 'warning' | 'info' | 'close' | 'exclamation' 

const notificationVisible = ref(false)
const notifications = ref([] as CustomNotification[])
const notificationsLeft = ref(0)
const showCloseButton = ref(true)

const EXCLUDED_PATHS = [
	'/register',
	'/login',
	'/profile-analysis',
	'/widget',
	'/choose-screen',
	'/forgot-password',
	'/email-verification',
	'/auth/action',
	'/preview-cv-builder',
];

export const useNotification = () => {
	const route = useRoute();

	const isExcludedPath = () => {
		return EXCLUDED_PATHS.some(path => route.path.startsWith(path));
	};

	const setSvg = (type: string) => {
		switch(type) {
			case 'success':
				return SuccessIcon
			case 'error':
				return ErrorIcon
			case 'warning':
				return Warning
			case 'close':
				return Close
			case 'exclamation':
				return Exclamation
			case 'info':
				return Info
			default:
				return Info
		}
	}

	const addNotification = (options: {
		text: string, 
		type: NotificationTpe, 
		title?: string,
		buttonText?: string,
		action?: () => void
	}, timeout: number | boolean = 3000, useTimeout: boolean = true) => {
		
		const timeoutNumber = typeof timeout === 'boolean' ? 3000 : timeout;
		useTimeout = typeof timeout === 'boolean' ? timeout : useTimeout

		if (isExcludedPath()) {
			console.log('🚫 Notification blocked on excluded path:', route.path);
			return;
		}

		console.log('✅ Showing notification on path:', route.path);
		const {text, type, title, buttonText, action} = options
		const svg = setSvg(type)
		notifications.value.push({
			text: text,
			svg: svg,
			title: title || '',
			buttonText,
			action
		})
		notificationVisible.value = true
		notificationsLeft.value++

		if(useTimeout) {
			setTimeout(() => {
				removeNotification()
			}, timeoutNumber)
		}
	}

	const removeNotification = () => {
		notifications.value.splice(0, 1)
		notificationsLeft.value--
		if(notificationsLeft.value === 0) {
			notificationVisible.value = false
		}
	}

	return {
		notificationVisible,
		notifications,
		notificationsLeft,
		showCloseButton,
		addNotification,
		removeNotification
	}
}

export default { 
	useNotification
}