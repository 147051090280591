<template>
  <div class="profile-picture-uploader">
    <input type="file" @change="handleFileSelect" ref="fileInput" style="display: none;" accept="image/*" />
    
    <!-- Image Container -->
    <div class="image-container" @click="triggerFileInput">
      <img :src="profilePictureUrl || defaultImage" alt="Profile Picture" class="upload-image" @error="handleImageError" />
      <div class="overlay">Upload Profile Picture</div>
    </div>
    
    <!-- Cropper Modal -->
    <div v-if="showCropper" class="cropper-modal-custom">
      <div class="cropper-content">
        <vue-cropper
          ref="cropper"
          :src="selectedImage"
          :aspect-ratio="1"
          :view-mode="1"
          :auto-crop-area="1"
          :responsive="true"
          :background="false"
        ></vue-cropper>

        <div class="button-container" style="margin-top: 10px;">
          <button class="button-common save" @click="confirmCrop">Crop & Upload</button>
          <button class="button-common cancel" @click="cancelCrop">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImage from '@/assets/profile.png';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { useProfileStore } from '@/store/profile';
import { storeToRefs } from 'pinia';
import { handleImageError } from '@/store/utils';

export default {
  components: {
    VueCropper
  },
  data() {
    return {
      defaultImage,
      showCropper: false,
      selectedImage: null,
      croppedFile: null,
    };
  },
	setup () {
		const { profilePictureUrl } = storeToRefs(useProfileStore())
		const { uploadProfilePicture } = useProfileStore()
		return {
			profilePictureUrl,
			uploadProfilePicture
		}
	},
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    
    handleFileSelect(event) {
      const file = event.target.files[0];
      if (file) {
        this.previewImage(file);
      } else {
        console.warn('No file selected.');
      }
    },
    
    previewImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedImage = e.target.result;
        this.showCropper = true;
      };
      reader.readAsDataURL(file);
    },
    
    confirmCrop() {
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        const croppedFile = new File([blob], 'profile_cropped.png', { type: 'image/png' });
        this.showCropper = false;
        this.uploadProfilePicture(croppedFile, true);
      }, 'image/png');
    },
    
    cancelCrop() {
      this.showCropper = false;
      this.selectedImage = null;
    },
    handleImageError,
  },
};
</script>

<style scoped>
.profile-picture-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.image-container {
  position: relative;
  cursor: pointer;
  width: 100%; /* Make it responsive */
  max-width: 200px; /* Max width for larger screens */
  padding-top: 100%; /* 1:1 Aspect Ratio */
  border-radius: 50%;
  overflow: hidden;
}

.upload-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  text-align: center; /* Center the text horizontally */
}

.image-container:hover .overlay {
  visibility: visible;
  opacity: 1;
}

.cropper-modal-custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.cropper-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 60px;
}


</style>
