<template>
  <div :class="layoutClass">
		<GradientBackground v-if="isGradientVisible" id="gradient-background">
			<template #gradient_components>
				<!-- Menu Transition -->
				<transition :name="menuTransition" @after-leave="performRouteChange">
				</transition>
					<!-- Desktop Menu -->
					<MenuDesktop 
						v-if="isMenuVisible" 
						@closeMenu="closeMenu" 
						@editProfile="handleProfileEdit()" 
					/>

				<!-- Header for Desktop -->
				<header
						:class="['header-desktop', { 'header-hidden': !isHeaderVisible, 'sidebar-visible': isOverlayVisible && !['/cv', '/cv-builder'].includes($route.path), 'sidebar-visible-cv': isOverlayVisible && ['/cv', '/cv-builder'].includes($route.path) }]"
						:style="{ 'background': 'transparent !important'}"
					>
					<div class="header-content">
						<button class="lionhead-logo-button">
							<router-link to="/" class="logo-link">
								<img class="logo" alt="Group" src="@/assets/lionhead-logo-symbol_white.svg" />
								<span class="logo-text">LionHead </span>
								<div v-if="isAuthenticated" class="notification-beta">Beta</div>
							</router-link>
						</button>
						<div class="header-route-buttons" v-if="!['/cv-builder'].includes($route.path)">
							<!-- Jobs Button -->
							<MenuButtonHeader
								v-if="!isAuthenticated && route.path == '/cv'"
								className="header-button-gradient"
								svgSrc="lionhead-logo.svg"
								altText="lionhead Icon"
								buttonText="Match"
								to="/"
								@click="openPopup"
								routeName="JobDetails"
								:notification="isAuthenticated ? unseenVacanciesCount : null"
							/>
							<MenuButtonHeader
								v-else
								className="header-button-gradient"
								svgSrc="lionhead-logo.svg"
								altText="lionhead Icon"
								buttonText="Match"
								to="/jobs"
								routeName="JobDetails"
								:notification="isAuthenticated ? unseenVacanciesCount : null"
							/>

							<MenuButtonHeader
								v-if="!isAuthenticated && route.path == '/cv'"
								className="header-button-gradient"
								svgSrc="bookmarks.svg"
								altText="Jobs Icon"
								buttonText="Jobs"
								to="/jobs"
								@click="openPopup"
								routeName="Jobs"
								:overlayValue="false"
							/>
							<MenuButtonHeader
								v-else
								className="header-button-gradient"
								svgSrc="bookmarks.svg"
								altText="Jobs Icon"
								buttonText="Jobs"
								to="/"
								routeName="Jobs"
								:overlayValue="false"
							/>
							<!-- Bookmarks Button -->
							<MenuButtonHeader
								v-if="isAuthenticated || !userTracking?.maxLimitReached"
								className="header-button-gradient"
								svgSrc="bookmarks.svg"
								altText="Saved Icon"
								buttonText="Bookmarks"
								to="/bookmarks"
								routeName="Bookmarks"
							/>
							<MenuButtonHeader
								v-else
								className="header-button-gradient"
								svgSrc="bookmarks.svg"
								altText="Saved Icon"
								buttonText="Bookmarks"
								:to="route.path"
								@click="openPopup"
								routeName="Bookmarks"
							/>
							<!-- Offers Button -->
							<!-- <MenuButtonHeader
								className="header-button-gradient"
								svgSrc="offer.svg"
								altText="Offer Icon"
								buttonText="Offer"
								to="/offers"
								routeName="Offers"
							/> -->
							<!-- CV Button -->
							<MenuButtonHeader
								v-if="isAuthenticated || profileState"
								className="header-button-gradient"
								svgSrc="CV.svg"
								altText="Inbox Icon"
								buttonText="My Profile"
								to="/cv"
								routeName="PreviewCV"
							/>
							<MenuButtonHeader
								v-else
								className="header-button-gradient"
								svgSrc="CV.svg"
								altText="Inbox Icon"
								buttonText="My Profile"
								to="/profile-analysis"
								routeName="PreviewCV"
							/>
						</div>
						<div class="dropdown" v-if="!['/cv-builder'].includes($route.path)">
							<div @click="toggleMenu()" class="logo-button">
								<img class="menu-button" alt="ProfileMenu" src="@/assets/whiteMenuVector.svg" />
								<div class="position-relative">
									<NotificationBubble v-if="isAuthenticated && bubbleNotifications && bubbleNotifications > 10" :unseen-notification="9" :plus-shown="true" class="notification-circle" :style="{top: '-8px', left: '0px'}"/>
										<NotificationBubble v-else-if="isAuthenticated && bubbleNotifications && bubbleNotifications <= 9" :unseen-notification="bubbleNotifications" class="notification-circle" :style="{top: '-8px', left: '0px'}" />
									<img
										class="profile-button-desktop"
										alt="Profile"
										v-if="profilePictureUrl"
										:src="profilePictureUrl"
										@error="handleImageError"
									/>
									<img v-else class="profile-button" alt="Profile" src="/assets/profileVector.svg" />
								</div>
							</div>
						</div>
						<div class="dropdown-builder" v-else>
							<button @click="toggleMenu()" class="button-common button-builder">
								Save your profile
							</button>
						</div>
					</div>
				</header>

				<!-- Main Content -->
				<main class="content 'desktop-header'" :class="[contentContainerClass]" style="margin-top: 100px;">
					<template v-for="(item, index) in gradientComponents" :key="index">
					
						<component
							:is="item.component"
							@child-scroll="handleChildScroll"
							:isHeaderVisible="isHeaderVisible"
							v-bind="item.params"
							v-on="filterEventHandlers(item.params)"
							:style="item.style"
						></component>
						<div v-if="item.params?.reference=='FilterHeader'" id="filter-height" style="height: 100px;"></div>
					</template>
				</main>

			</template>
		</GradientBackground>
    <!-- Menu Transition -->
    <transition :name="menuTransition" @after-leave="performRouteChange">
    </transition>
      <!-- Desktop Menu -->
      <MenuDesktop 
        v-if="isMenuVisible" 
        @closeMenu="closeMenu" 
        @editProfile="handleProfileEdit()" 
      />

    <!-- Header for Desktop -->
    <header
        v-if="!isGradientVisible"
        :class="['header-desktop', { 'header-hidden': !isHeaderVisible, 'sidebar-visible': isOverlayVisible && !['/cv', '/cv-builder'].includes($route.path), 'sidebar-visible-cv': isOverlayVisible && ['/cv', '/cv-builder'].includes($route.path),
					'animated': isGradientVisible && !isGradientInsideView
				}]"
      >
      <div class="header-content">
        <button class="lionhead-logo-button">
          <router-link to="/" class="logo-link">
            <img class="logo" alt="Group" src="@/assets/lionhead-logo-symbol_white.svg" />
            <span class="logo-text">LionHead </span>
            <div v-if="isAuthenticated" class="notification-beta">Beta</div>
          </router-link>
        </button>
        <div class="header-route-buttons" v-if="!['/cv-builder'].includes($route.path)">
          <!-- Jobs Button -->
					<MenuButtonHeader
						v-if="!isAuthenticated && route.path == '/cv'"
            className="header-button-gradient"
            svgSrc="lionhead-logo.svg"
            altText="lionhead Icon"
            buttonText="Match"
            to="/"
						@click="openPopup"
            routeName="JobDetails"
            :notification="isAuthenticated ? unseenVacanciesCount : null"
          />
					<MenuButtonHeader
						v-else
            className="header-button-gradient"
            svgSrc="lionhead-logo.svg"
            altText="lionhead Icon"
            buttonText="Match"
            to="/jobs"
            routeName="JobDetails"
            :notification="isAuthenticated ? unseenVacanciesCount : null"
          />

					<MenuButtonHeader
						v-if="!isAuthenticated && route.path == '/cv'"
            className="header-button-gradient"
            svgSrc="bookmarks.svg"
            altText="Jobs Icon"
            buttonText="Jobs"
            to="/jobs"
						@click="openPopup"
            routeName="Jobs"
            :overlayValue="false"
          />
					<MenuButtonHeader
						v-else
            className="header-button-gradient"
            svgSrc="bookmarks.svg"
            altText="Jobs Icon"
            buttonText="Jobs"
            to="/"
            routeName="Jobs"
            :overlayValue="false"
          />
          <!-- Bookmarks Button -->
          <MenuButtonHeader
						v-if="isAuthenticated || !userTracking?.maxLimitReached"
            className="header-button-gradient"
            svgSrc="bookmarks.svg"
            altText="Saved Icon"
            buttonText="Bookmarks"
            to="/bookmarks"
            routeName="Bookmarks"
          />
					<MenuButtonHeader
						v-else
            className="header-button-gradient"
            svgSrc="bookmarks.svg"
            altText="Saved Icon"
            buttonText="Bookmarks"
						:to="route.path"
            @click="openPopup"
            routeName="Bookmarks"
          />
          <!-- Offers Button -->
          <!-- <MenuButtonHeader
            className="header-button-gradient"
            svgSrc="offer.svg"
            altText="Offer Icon"
            buttonText="Offer"
            to="/offers"
            routeName="Offers"
          /> -->
          <!-- CV Button -->
          <MenuButtonHeader
						v-if="isAuthenticated || profileState"
            className="header-button-gradient"
            svgSrc="CV.svg"
            altText="Inbox Icon"
            buttonText="My Profile"
            to="/cv"
            routeName="PreviewCV"
          />
					<MenuButtonHeader
						v-else
            className="header-button-gradient"
            svgSrc="CV.svg"
            altText="Inbox Icon"
            buttonText="My Profile"
            to="/profile-analysis"
            routeName="PreviewCV"
          />
        </div>
        <div class="dropdown" v-if="!['/cv-builder'].includes($route.path)">
          <div @click="toggleMenu()" class="logo-button">
            <img class="menu-button" alt="ProfileMenu" src="@/assets/whiteMenuVector.svg" />
            <div class="position-relative">
							<NotificationBubble v-if="isAuthenticated && bubbleNotifications && bubbleNotifications > 10" :unseen-notification="9" :plus-shown="true" class="notification-circle" :style="{top: '-8px', left: '0px'}"/>
								<NotificationBubble v-else-if="isAuthenticated && bubbleNotifications && bubbleNotifications <= 9" :unseen-notification="bubbleNotifications" class="notification-circle" :style="{top: '-8px', left: '0px'}"/>
							<img
								class="profile-button-desktop"
								alt="Profile"
								v-if="profilePictureUrl"
								:src="profilePictureUrl"
								@error="handleImageError"
							/>
            	<img v-else class="profile-button" alt="Profile" src="/assets/profileVector.svg" />
						</div>
          </div>
        </div>
				<div class="dropdown-builder" v-else>
          <button @click="toggleMenu()" class="button-common button-builder">
						Save your profile
          </button>
        </div>
      </div>
    </header>

    <!-- Main Content -->
    <main class="content desktop-header" id="content" :class="[contentContainerClass]"
		:style="isGradientVisible ? { 'margin-top': 0 } : {}" >
			<router-view v-slot="{ Component, route }">
				<transition :name="isMenuPage ? 'slide-fade' : 'none'">
					<span :key="route.name"> 
						<component
							:is="Component"
							@child-scroll="handleChildScroll"
							@update-unseen-count="updateUnseenVacanciesCount"
							:isHeaderVisible="isHeaderVisible"
						></component>
					</span>
				</transition>
			</router-view>
    </main>

    <!-- Feedback Button -->
    <button class="feedback-button" @click="showFeedbackModal = true">
      <img src="@/assets/comment.svg" alt="Feedback" />
    </button>

    <!-- Feedback Modal -->
    <FeedbackModal
      :show="showFeedbackModal"
      @close="showFeedbackModal = false"
      :componentTitle="currentComponentTitle"
      :scrollPosition="scrollPosition"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch, onBeforeMount, nextTick } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import MenuButtonHeader from "@/components/CustomComponents/MenuButtonHeader.vue";
import FeedbackModal from "@/components/ProfilePages/feedback.vue";
import MenuDesktop from '@/components/ProfilePages/MenuDesktop.vue';
import { throttle } from 'lodash'; // Import lodash for throttling
import { auth } from "@/firebase"
import { storeToRefs } from 'pinia';
import { useGlobalStore } from '@/store/store';
import { useProfileStore } from '@/store/profile';
import { usePopup } from '@/composables/usePopup';
import NotificationBubble from '../CustomComponents/NotificationBubble.vue';
import { useSkillsStore } from '@/store/skills';
import GradientBackground from '../CustomComponents/GradientBackground.vue';
import { useGradientBackground } from '@/composables/useGradientBackground';
import { useCookie } from '@/composables/useCookie';
import { handleImageError } from '@/store/utils';

defineEmits(['childScroll', 'updateUnseenCount']);

const { passion, isOverlayVisible, education, experiences, selectedValues, personalInfo, ambitionFormCompleted } = storeToRefs(useGlobalStore())
const { profilePictureUrl } = storeToRefs(useProfileStore())
const { isSkillsVerified, areSkillImproved } = storeToRefs(useSkillsStore())
const router = useRouter();
const route = useRoute();
const { gradientComponents, isGradientVisible, isGradientInsideView, addStyleToComponent } = useGradientBackground()
const isHeaderVisible = ref(true);
const lastScrollTop = ref(0);
const showFeedbackModal = ref(false);
const isMenuVisible = ref(false);
const scrollPosition = ref(0);
const lastScrollDirection = ref('down');
const unseenVacanciesCount = ref(0);
let nextRoute = null;
const counterScale = ref(1)
const profileState = localStorage.getItem('profileState') ? JSON.parse(localStorage.getItem('profileState')) : null;
const { getCookie } = useCookie()

// Computed properties
const isMenuPage = computed(() => route.name === 'Menu');
const currentComponentTitle = computed(() => route.name);
const menuTransition = ref('slide-right');

const userTracking = getCookie('userTracking')

const { openPopup } = usePopup()

const isAuthenticated = computed(() => {
  return auth.currentUser !== null
})

const layoutClass = computed(() => ({
      layout: true,
    }));


// Handlers
const toggleMenu = () => {
  if (!isMenuVisible.value) {
    // Opening the menu (slide in from the right)
    menuTransition.value = 'slide-right';
    isMenuVisible.value = !isMenuVisible.value;
  } else {
    // Closing the menu (slide out to the right)
    menuTransition.value = 'slide-right';
    isMenuVisible.value = !isMenuVisible.value;
  }
};

const contentContainerClass = computed(() => {
	if (isHeaderVisible.value) {
		return isHeaderVisible.value ?
		'content-container-with-desktop-header':
		'content-container-no-header-desktop';
	}
});

const bubbleNotifications = computed(() => {
	const defualPassion = 'Passionate about extreme ironing, competitive cheese rolling, and underwater basket weaving. Avid collector of novelty rubber ducks and an enthusiastic participant in llama yoga. When not perfecting my skills in interpretive dance with houseplants, I enjoy hosting dinosaur-themed tea parties and volunteering as a professional hide-and-seek champion at local libraries. Also, a proud member of the international jellybean flavor taste-testing committee.'
	let tot = 0
	if(selectedValues.value.length === 0) tot += 1
	if(Object.values(personalInfo.value).some((item) => item === '' || item === null)) {
		tot += Object.values(personalInfo.value).filter((item) => item === '' || item === null).length
	}
	if(!ambitionFormCompleted.value) tot += 9
	if(!isSkillsVerified.value) tot += 1
	if(!areSkillImproved.value) tot += 1
	if(passion.value === defualPassion) tot += 1
	if(!personalInfo.value.summary) tot += 1
	if(!education.value.length) tot += 1
	if(!experiences.value.length) tot += 1

	return tot
});


const handleProfileEdit = () => {
  // Set the menu to slide out to the left
  menuTransition.value = 'slide-left';
  isMenuVisible.value = !isMenuVisible.value;
  nextRoute = { name: 'Personal' }; // Store the route, but don't navigate yet
};

const performRouteChange = () => {
  // After the transition, perform the route change
  if (nextRoute) {
    router.push(nextRoute);
    nextRoute = null; // Reset the nextRoute
  }
};

const closeMenu = () => {
  // Close the menu with a slide out to the right
  menuTransition.value = 'slide-right';
  isMenuVisible.value = false;
};

// Scroll Handling
const handleScroll = (event) => {
  const scrollTop = event?.target.scrollTop;
  const scrollHeight = event?.target.scrollHeight;
  const clientHeight = event?.target.clientHeight;

  if (scrollTop + clientHeight >= scrollHeight) {
    // Reached the bottom, keep header hidden
    isHeaderVisible.value = false;
  } else {
    if (scrollTop > lastScrollTop.value) {
      if (lastScrollDirection.value !== 'down') {
        isHeaderVisible.value = false;
        lastScrollDirection.value = 'down';
      }
    } else {
      if (lastScrollDirection.value !== 'up') {
        isHeaderVisible.value = true;
        lastScrollDirection.value = 'up';
      }
    }
  }

  lastScrollTop.value = scrollTop <= 0 ? 0 : scrollTop;
  scrollPosition.value = scrollTop; // Updated to use scrollPosition instead of scrollHeight
};

const throttledHandleScroll = throttle(handleScroll, 100); // Throttle the scroll handler

const handleChildScroll = (event) => {
  throttledHandleScroll(event);
};

const handleGradientVisibility = () => {
	isGradientInsideView.value = isBackgroundVisible()
	manageCounterComponentScale()
} 

// Update unseen vacancies count
const updateUnseenVacanciesCount = (count) => unseenVacanciesCount.value = count;

const isBackgroundVisible = () => {
	const el = document.getElementById('gradient-background')
	if(el) {
		const rect = el.getBoundingClientRect()
		return !(Math.abs(rect.top) >= rect.height - 60)
	}
	return false
}

const manageCounterComponentScale = (rect= null) => {
	if(!isGradientInsideView.value) return

	if(!rect) {
		const el = document.getElementById('gradient-background')
		const content = document.getElementById('content')
		
		if(el && content) {
			const rect = el.getBoundingClientRect()
			const cont = content.getBoundingClientRect()

      const height = rect.height * 0.5
			counterScale.value = Math.abs(rect.top + height) / height

			if(rect.height === 0 || cont.height === window.innerHeight) return;
			
			if(counterScale.value < 0) return

			let counterStyle = `transform: scale(${counterScale.value});`
			if(el.style.position == 'fixed') {
				if(Math.ceil(cont.top - 100) >= Math.ceil(rect.height)) {
					content.style.marginTop = '0'
					content.style.top = '0'
					el.style.position = 'relative'
					counterStyle += 'display: block;'
				}
			} else {
				if(counterScale.value < 0.7) {
					counterStyle += 'display: none;'
					el.style.position = 'fixed'
					el.style.width = '100%'
					el.style.top = '0'
					el.style.zIndex = '1'
					content.style.marginTop = `${Math.abs(rect.height / 2) + 150}px`
				}
			}

			nextTick(() => {
				addStyleToComponent('CounterComponent', counterStyle)
				addStyleToComponent('TextComponent', counterStyle)
			})
		}
	} else {
		counterScale.value = Math.abs(rect.top + rect.height) / rect.height
		const counterStyle = `transform: scale(${counterScale.value}) `
		
		addStyleToComponent('CounterComponent', counterStyle)
		addStyleToComponent('TextComponent', counterStyle)
	}
}

function filterEventHandlers(params) {
  return Object.fromEntries(
    Object.entries(params || {}).filter(([key, value]) => {
      return typeof value === 'function';
    })
  );
}

// Lifecycle hooks
onMounted(() => {
  console.log('Parent component mounted'); // Debugging statement
  const mainContent = document.querySelector('.content');
  if (mainContent) {
    mainContent.addEventListener('scroll', throttledHandleScroll());
  }
	document.addEventListener('scroll', handleGradientVisibility)
});

onBeforeUnmount(() => {
  // Remove scroll event listener from the main content
  const mainContent = document.querySelector('.content');
  if (mainContent) {
    mainContent.removeEventListener('scroll', throttledHandleScroll());
  }
	document.removeEventListener('scroll', handleGradientVisibility)
});
</script>

<style scoped>
.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
	background-color: #EDF4FF;
}

.button-builder {
	border: 1px solid #056cf2;
	background-color: #056cf2;
	color: #ffffff;
	width: 100%;
	font-size: 16px;
	padding: 10px 20px;
}

.button-builder:hover {
	background: #0457c8;
}

.header-desktop {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
	background: linear-gradient(66deg, rgba(5,108,242,1) 0%, rgba(3,62,140,1) 62%, rgba(3,62,140,1) 94%);
  z-index: 2000;
  touch-action: manipulation; 
  position: fixed;
}

.animated {
	transition: all 0.3 ease-in-out;
}

.header-route-buttons {
  display: flex;
	flex: 1 0;
  flex-direction: row;
	justify-content: end;
	align-items: center;
  gap: 5px;
  margin-right: 50px;
}

.header-hidden {
  transform: translateY(-100%);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 15px;
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo {
  width: 34px;
  height: auto;
}

.logo-text {
  position: relative;
  left: 5px;
  font-family: Gilmer;
  font-style: normal;
  line-height: normal;
  margin-left: 5px;
  font-size: 19px;
  color: #fff;
}

.lionhead-logo-button {
  position: relative;
  cursor: pointer;
  background-color: transparent;
	margin-right: 30px;
}

.logo-button {
  position: relative;
  cursor: pointer;
  width: 84px;
  height: 46px;
  background: transparent;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown {
  position: relative;
}

.menu-button {
  width: 30px;
  height: 30px;
}

.profile-img {
	width: 30px;
  height: auto;
  fill: #056cf2;
  color: #056cf2;
	border-radius: 50%;
}

.profile-button-desktop {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* border: 1px solid #ced4e0; */
  object-fit: cover;
  margin-left: 10px; /* Add spacing if necessary */
}

.content {
  flex: 1 0 auto;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

.no-padding-top {
  padding-top: 0 !important;
}

.content-container-with-desktop-header {
  width: 100%;
  /* Adjust the height if necessary */
  overflow-y: auto;
  box-sizing: border-box;  
  margin-top: 60px;

}

.content-container-no-header-desktop {
  width: 100%;
  height: calc(100vh);
  overflow-y: auto;
  box-sizing: border-box;
}

.notification-circle {
  position: absolute;
  top: -4px;
  left: -8px;
  height: 18px;
  width: 18px;
  background-color: #ff832b;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
  font-weight: light;
}

.notification-beta {
  position: absolute;
  top: 0px;
  right: -28px;
  background-color: #ff832b;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
  font-weight: light;
  z-index: 100;
  padding: 2px 5px;
}

.feedback-button {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #056cf2;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  z-index: 100;
}

.feedback-button img {
  width: 24px;
}

.feedback-button:hover {
  background-color: #045bb5;
}

/* Transition for sliding in from the right */
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.slide-right-enter-from {
  transform: translateX(100%);
  opacity: 0;
}

.slide-right-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.slide-right-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.slide-right-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

/* Transition for sliding out to the left */
.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.slide-left-enter-from {
  transform: translateX(100%);
  opacity: 0;
}

.slide-left-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.slide-left-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.slide-left-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.none-enter-active,
.none-leave-active {
  transition: none;
}

</style>
