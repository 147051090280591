// ./modules/software.js

import { v4 as uuidv4 } from 'uuid';
import { SoftwarePackage } from '@/types';
import { ref, computed, toRaw } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import apiClient from '@/axios';
import { auth } from '@/firebase';
import { useSkillsStore } from './skills';

export const useSoftwareStore = defineStore( 'software', () => {

	const isAuthenticated = computed(() => auth.currentUser !== null);
	const softwarePackages = ref<SoftwarePackage[]>([])

	async function setSoftware(packages: SoftwarePackage) {
		if (!Array.isArray(packages)) {
			console.error("softwarePackages is not an array:", packages);
			return;
		}
		// Process software packages
		const processedSoftwarePackages = packages.map(software => ({
			id: software.id || uuidv4(),
			name: software.name || '',
			weight: software.weight || software.level || 0,
			type: software.type || '',
			changeCounter: software.changeCounter || 0,
			improve: software.improve || 0,
		}));
		softwarePackages.value = processedSoftwarePackages;
	}

	const initializeSoftware = () => {
		if (!Array.isArray(softwarePackages.value)) {
			console.error("softwarePackages is not an array or is undefined:", softwarePackages.value);
			return;
		}

		const updatedSoftware = softwarePackages.value.map(software => ({
			...software,
			id: software.id || uuidv4(),
		}));
		softwarePackages.value = updatedSoftware;
	}

	const addSoftware = (software: SoftwarePackage) => {
		// Generate ID if not present
		software.id = software.id || uuidv4();
		softwarePackages.value.push({
			id: software.id || uuidv4(),
			name: software.name,
			weight: software.weight || 0,
			changeCounter: 0,
			improve: software.improve || 0,
			newSkill: software.newSkill || false,
			checked: software.checked || false,
		});
		// Push to dataLayer
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'software_added',
			software: {
				id: software.id,
				name: software.name,
			},
		});
	}

	const updateSoftwareChecked = (payload: { id: string, checked: boolean }) => {
		const softwarePackage = softwarePackages.value.find(
			(software: SoftwarePackage) => software.id === payload.id
		);
		if (softwarePackage) {
			softwarePackage.checked = payload.checked;
			softwarePackage.changeCounter = (softwarePackage.changeCounter || 0) + 1;
		}
	}

	const resetAllChecked = () => {
		softwarePackages.value.forEach((software: SoftwarePackage) => {
			software.checked = false;
		});
	}

	const deleteSoftware = (id: string) => {
		const software = softwarePackages.value.find(s => s.id === id);
		if (software) {
			softwarePackages.value = softwarePackages.value.filter((software: SoftwarePackage) => software.id !== id);
			// Push to dataLayer
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: 'software_deleted',
				software: {
					id: software.id,
					name: software.name,
				},
			});
		} else {
			console.error(`Software with id ${id} not found.`);
		}
	}

	const updateSoftwareName = (payload: { id: string, name: string }) => {
		const softwarePackage = softwarePackages.value.find((software: SoftwarePackage) => software.id === payload.id);
		if (softwarePackage) {
			softwarePackage.name = payload.name;
			softwarePackage.changeCounter = (softwarePackage.changeCounter || 0) + 1;
		}
		// Push to dataLayer
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'software_name_updated',
			software: {
				id: payload.id,
				name: payload.name,
			},
		});
	}

	const setSoftwarePackageWeight = (payload: { id: string, weight: number }) => {
		const softwarePackage = softwarePackages.value.find((software: SoftwarePackage) => software.id === payload.id);
		if (softwarePackage) {
			softwarePackage.weight = payload.weight;
			softwarePackage.changeCounter = (softwarePackage.changeCounter || 0) + 1;
		}
		// Push to dataLayer
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'software_weight_updated',
			software: {
				id: payload.id,
				newWeight: payload.weight,
			},
		});
	}

	const updateSoftwareImprove = (payload: { id: string, improve: number }) => {
		const softwarePackage = softwarePackages.value.find((software: SoftwarePackage) => software.id === payload.id);
		if (softwarePackage) {
			softwarePackage.improve = payload.improve;
			softwarePackage.changeCounter = (softwarePackage.changeCounter || 0) + 1;
		}
		// Push to dataLayer
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'software_improve_updated',
			software: {
				id: payload.id,
				improve: payload.improve,
			},
		});
	}

	async function saveSoftware(){
		const profileState = localStorage.getItem('profileState')
		? JSON.parse(localStorage.getItem('profileState') as string)
		: null;

		if(!isAuthenticated.value && profileState) {
			profileState.software = softwarePackages.value;
			localStorage.setItem('profileState', JSON.stringify(profileState));
		} else {
			const skills = toRaw(useSkillsStore().skills)

			apiClient.post('/save_state', {
				partialState: {
					'skills': skills,
					'software': softwarePackages.value
				}
			})
		}
	}

	return {
		softwarePackages,
		setSoftware,
		initializeSoftware,
		addSoftware,
		updateSoftwareChecked,
		resetAllChecked,
		deleteSoftware,
		updateSoftwareName,
		setSoftwarePackageWeight,
		updateSoftwareImprove,
		saveSoftware
	}
})

