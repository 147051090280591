import { ref } from "vue"
import { useCookie } from  "@/composables/useCookie"
import { useFingerprint } from "./useFingerprint"

type UserTracking = {
	fingerprint: string
	jobsSeen: string
	maxLimitReached: boolean
}

const MAX_ITEMS = ref(Infinity)

export const useTracking = () => {
	const { getCookie, updateCookie } = useCookie()
	const { generateFingerprint } = useFingerprint()

	const manageTracking = async(id: string | string[]) => {
		const userTracking: UserTracking = getCookie('userTracking')
		
		if(!userTracking?.fingerprint) {
			const fingerprint = await generateFingerprint();
			updateCookie('userTracking', 'fingerprint', fingerprint)
		}

		if(!userTracking?.maxLimitReached) {
			updateCookie('userTracking', 'maxLimitReached', false)
		}

		if(!userTracking?.jobsSeen) {
			updateCookie('userTracking', 'jobsSeen', JSON.stringify([id]))
			console.log('First job viewed:', id);
		} else {
			let seen = JSON.parse(userTracking.jobsSeen)

			if(seen.length >= MAX_ITEMS.value) {
				console.log('Max limit reached! Total views:', seen.length);
				updateCookie('userTracking', 'maxLimitReached', true)
			} else {
				if(!seen.includes(id)) {
					seen.push(id)
					console.log('Jobs viewed so far:', seen.length);
					updateCookie('userTracking', 'jobsSeen', JSON.stringify(seen))
				}
			}
		}
	}

	return {
		manageTracking
	}
}

export default { useTracking }