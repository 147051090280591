import _ from 'lodash';
import apiClient from '@/axios';
import { auth } from '@/firebase';
import { PiniaPluginContext } from 'pinia';
import { RootState } from '../states';
import { useGlobalStore } from '../store';
import { useVacanciesStore } from '../vacancies';
import { useSkillsStore } from '../skills';
import { useSoftwareStore } from '../software';
import { useProfileStore } from '../profile';

export function saveStatePlugin({ store }: PiniaPluginContext) {
  let lastSavedState = {};
  let lastSaveTime = 0;
  const MIN_SAVE_INTERVAL = 1000;

  const saveState = async(payload: Record<string, unknown> | null = null) => {
		if(import.meta.env.VITE_APP_ENV === 'test') return

    // Clone and filter vacancies module state
		console.log(`triggering save state`);

		// Access each store's state directly
		const vacanciesStore = useVacanciesStore();
		const skillsStore = useSkillsStore();
		const softwareStore = useSoftwareStore();
		const globalStore = useGlobalStore();
		const profileStore = useProfileStore();
		
		const globalState = _.cloneDeep(globalStore.$state);
		const skillsState = _.cloneDeep(skillsStore.$state);
		const softwareState = _.cloneDeep(softwareStore.$state);
    const vacanciesState = _.cloneDeep(vacanciesStore.$state);
		const profileStoreState = _.cloneDeep(profileStore.$state);
  
    // Create a new object excluding unnecessary properties (at all levels)
    const vacanciesStateToSave = _.omit(vacanciesState, [
      'vacancies',
      'bookmarkedVacancies',
      'loading',
      'selectedVacancyId',
      'vacanciesByPage', 
      'filters', 
      'vacanciesIds',
      'currentPage',
    ]);

    // Construct the state to save
    const stateToSave = {
      experiences: globalState.experiences,
      linkedinSkills: globalState.linkedinSkills,
      personalInfo: globalState.personalInfo,
      education: globalState.education,
      formData: globalState.formData,
      selectedValues: globalState.selectedValues,
      passion: globalState.passion,
      ambitionFormCompleted: globalState.ambitionFormCompleted,
      matchOverlayShown: globalState.matchOverlayShown,
      skills: skillsState.skills,
      software: softwareState.softwarePackages,
			areValuesShownOnSummary: globalState.areValuesShownOnSummary,
			areAmbitionsShownOnSummary: globalState.areAmbitionsShownOnSummary,
			areEducationShownOnSummary: globalState.areEducationShownOnSummary,
			areExperiencesShownOnSummary: globalState.areExperiencesShownOnSummary,
			arePassionsShownOnSummary: globalState.arePassionsShownOnSummary,
			areSkillsShownOnSummary: globalState.areSkillsShownOnSummary,
			isSummaryShown: globalState.isSummaryShown,
  
      // Save only the filtered vacancies state
      vacancies: vacanciesStateToSave,
    };
		
		const profileState = localStorage.getItem('profileState')
			? JSON.parse(localStorage.getItem('profileState') as string)
			: null;

		try {
			if(payload?.provenience == 'registration' && payload?.userId) {
				apiClient.defaults.headers.common['Authorization'] = `Bearer ${payload.userId}`;
				apiClient
					.post('/save_state', { state: stateToSave })
					.then((response) => {
					})
					.catch((error) => {
						console.error('Error saving state:', error);
					});
	
				delete apiClient.defaults.headers.common['Authorization'];
	
			} else if(!auth.currentUser && profileState) {
				const newState = {
					...stateToSave,
					profilePictureUrl: profileStoreState.profilePictureUrl,
				};
				
				localStorage.setItem('profileState', JSON.stringify(newState));
			} else {
				// Proceed with saving to MongoDB
				apiClient
					.post('/save_state', { state: stateToSave })
					.then((response) => {
					})
					.catch((error) => {
						console.error('Error saving state:', error);
					});
			}
		} catch (e) {
			for(let i = globalState.attempts; i < globalState.maxAttempts; i++) {
				await new Promise((resolve) => setTimeout(resolve, 5000));
				globalStore.attempts++;
				saveState(payload);
			}
		}
  };
  

  // Debounced version for automatic saving
  const saveStateDebounced = _.debounce(saveState, 1000);

  const mutationsThatTriggerSave = [
    // State mutations
    'updatePassion',
    'setAmbitionFormCompleted',
    'toggleValue',
    'setSelectedValues',
     // State actions
		 'setStateAndInitialize',
		 'savePersonalInfo',
		 'savePassion',
		 'completeAmbitionForm',
		 'setSelectedValues',
		 // Education actions
		 'addNewEducation',
		 'saveEducation',
		 'updateEducation',
		 'removeEducation',
		 // Experience actions
		 'addNewExperience',
		 'saveExperience',
		 'updateExperience',
		 'removeExperience',
		 // Skills actions
		 'skills/addSkill',
		 'skills/deleteSkill',

		 'skills/updateSkillWeight',
		 'skills/updateSubSkillWeight',
		 'skills/updateSkillImprove',
		 'skills/updateSubSkillImprove',
		 // Software actions
		 'software/addSoftware',
		 'software/deleteSoftware',
		 'software/updateSoftwareName',
		 'software/setSoftwarePackageWeight',
		 'software/updateSoftwareImprove',
		 // Vacancies actions
		 'vacancies/addSeenVacancy',
		 'vacancies/addToBookmarkedVacancies',
		 'vacancies/removeVacancyBookmark',
		 'vacancies/addToIgnoredVacancies',
  ];

  const actionsThatTriggerSave = [
    // State actions
    'setStateAndInitialize',
    'savePersonalInfo',
    'savePassion',
    'completeAmbitionForm',
    'setSelectedValues',
    // Education actions
    'addNewEducation',
    'saveEducation',
    'updateEducation',
    'removeEducation',
    // Experience actions
    'addNewExperience',
    'saveExperience',
    'updateExperience',
    'removeExperience',
    // Skills actions
    'addSkill',
    'deleteSkill',
		'updateSkill',
    'updateSkillWeight',
    'updateSubSkillWeight',
    'updateSkillImprove',
    'updateSubSkillImprove',
    // Software actions
    // 'addSoftware',
    'deleteSoftware',
    // 'updateSoftwareName',
    // 'setSoftwarePackageWeight',
    // 'updateSoftwareImprove',
    // Vacancies actions
    'addSeenVacancy',
    'addToBookmarkedVacancies',
    'removeVacancyBookmark',
    'addToIgnoredVacancies',
    // Add any other actions that affect stateToSave properties
		'saveAll'
  ];
  
  

  store.$subscribe((mutation) => {
    // Do not save state if the user is not logged in
    const user = auth.currentUser;

    if (!user) {
      return;
    }

    // Do not save state during logout or login process
    if (useGlobalStore().isLoggingOut || useGlobalStore().isLoggingIn) {
      return;
    }

    // Exclude specific mutations from triggering a save
    const excludedMutations = ['INIT_STORE', 'CLEAR_STATE', 'setLoggingOut'];
    if (excludedMutations.includes(mutation.type)) {
      return;
    }

    // Only save state if the mutation type is in the whitelist
    if (!mutationsThatTriggerSave.includes(mutation.type)) {
      return;
    }

    // Debugging log: Track mutations that trigger state saving
    console.log(`Mutation triggered: ${mutation.type}`);
    saveStateDebounced();
  });


  store.$onAction(({ name, after, onError }) => {
    after(() => {
      const user = auth.currentUser;
			const profileState = localStorage.getItem('profileState') ? JSON.parse(localStorage.getItem('profileState') as string) : null;

			if(!user && profileState && actionsThatTriggerSave.includes(name)) {
				saveStateDebounced();
			}

      if (!user || useGlobalStore().isLoggingOut || useGlobalStore().isLoggingIn) {
        return;
      }
      
      // Do not save state during logout or login process
      if (useGlobalStore().isLoggingOut || useGlobalStore().isLoggingIn) {
        return;
      }


      if (actionsThatTriggerSave.includes(name)) {

        saveStateDebounced();
      }
    }),

		onError((error) => {
			console.error(`Action error: ${error}`);
		});
  });

  // Expose the saveState function on the store for manual triggering
  store.saveState = (payload: Record<string, unknown>|null = null) => {
    // Ensure the user is logged in before saving
    const user = auth.currentUser;
    if (!user) {
      console.warn('Cannot save state: User is not logged in.');
      return;
    }

    // Check for special user and login/logout states
    if (useGlobalStore().isSpecialUser || useGlobalStore().isLoggingOut || useGlobalStore().isLoggingIn) {
      console.warn('Cannot save state: Invalid user state.');
      return;
    }

    // Call the immediate saveState function
    saveState(payload);
  };

	return {
		saveState,
	}
};

export default saveStatePlugin;
