<template>
	<div class="content-container" v-show="showMatchOveraly" @click="showMatchOveraly = false">
		<div class="match-block-container my-4">
			<div class="top-part">
				<img src="@/assets/white_star.svg" alt="star" />
				<p class="mt-2 mb-0 subtitle">Your new employer</p>
				<h3>It's a match!</h3>
				<div class="rating-container">
					<img class="star" src="@/assets/white_star.svg" alt="star" />
					<span class="stars-color mx-1 rate">8/8</span>
					<span class="percentage">100%</span>
				</div>
			</div>
			<div class="bottom-part">
				<div class="round-image-container">
					<div class="round-image left">
						<img v-if="profilePictureUrl" :src="profilePictureUrl" alt="profile picture" @error="handleImageError">
						<img v-else :src="defaultImage" alt="profile vector">
					</div>
					<div class="round-image right">
						<img src="@/assets/images/yourdreamjob.png" alt="match sidebar image">
					</div>
				</div>
				<div class="description-container">
					<div class="subt">Welcome to LionHead, {{ personalInfo.fullName }}</div>
					<p class="desc">This AI-generated CV is the starting point for daily matches with the best jobs in the world. For every job opening, you’ll see how well you match based on your skills and personal ambitions. Update your profile to take control of your career. Your future is in your hands. Wishing you great success!</p>
				</div>
			</div>

			<div class="action-part">
				<button class="btn" @click="goTo('cv')">Check my CV</button>
				<button class="btn blue" @click="goTo('jobs')">Match me to jobs!</button>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useProfileStore } from '@/store/profile';
import { storeToRefs } from 'pinia';
import { handleImageError } from '@/store/utils'
import defaultImage from '@/assets/profile.png';
import { useRouter } from 'vue-router';
import { useGlobalStore } from '@/store/store';

const { profilePictureUrl } = storeToRefs(useProfileStore());
const { showMatchOveraly, personalInfo } = storeToRefs(useGlobalStore())

const router = useRouter()

const goTo = (where: string) => {
	switch (where){
		case 'cv':
			router.push({name: 'PreviewCV'})
			break
		case 'jobs':
			router.push({name: 'JobsDetailsNoId'})
	}
}
</script>

<style scoped>
.content-container {
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(5, 108, 242, .6);
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 2000;
}

.match-block-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	width: 30%;
	background: linear-gradient(to bottom, #056cf2 0%, #056cf2 30%, #ffffff 30%)
}

.match-block-container h3 {
	color: #fff;
	font-weight: 800;
	margin-bottom: 3px;
}

.hover_button:hover {
	transform: scale(1.05);
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.subtitle {
	font-size: 10px;
}

.rating-container {
	display: flex;
	align-items: baseline;
	justify-content: center;
	font-size: 9px;
}

.rating-container .rate {
	font-weight: 800;
}

.rating-container .percentage {
	font-size: 7px;
	font-weight: 800;
}

.rating-container .star {
	width: 10px;
	height: auto;
}

.round-image-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.round-image-container .round-image {
	border-radius: 50%;
	background: white;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
	overflow: hidden;
	width: 50%;
	height: auto;
	padding: 10px;
	max-width: 161px;
	max-height: 161px;
}

.round-image-container .round-image.left {
	position: relative;
	left: 7px;
	z-index: 2;
}

.round-image-container .round-image.right {
	position: relative;
	right: 7px;
	z-index: 1;
}

.round-image-container .round-image img {
	width: 100%;
	height: auto;
	border-radius: 50%;
}

.container {
	padding: 40px 30px;
}

.stars-color {
	color: #fff;
}

.description-container {
	margin: 20px auto;
	width: 80%;
}

.container p {
	font-size: 14px;
	gap: 20px;
}

.description-text {
	margin-bottom: 80px;
	/* Adjust the value as needed */
}

.button {
	box-sizing: border-box;
	margin: 20px 0;
	height: 39px;
	padding: 10px 20px;
	background: #056cf2;
	border-radius: 4px;
	font-family: "Inter", sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 160%;
	color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 0px;
}

.skills-match-container {
	padding-left: 10px;
}

.skills-matches:nth-child(odd) {
	background-color: #f3f7fa;
}

.skills-matches {
	border-bottom: 1px solid #E1E4EB;
}

.skills-matches:first-child {
	border-bottom: 1px solid #E1E4EB;
}

.skills-matches:last-child {
	border-bottom: none;
}

.skills-match-container .skills-matches {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 4px 10px;
	font-size: 10px;
	color: #000000;
}

.skills-match-container .skills-matches img {
	width: 12px;
	height: 12px;
}

.custom-size-spinner {
	width: 1.5rem;
	height: 1.5rem;
	opacity: 0.8;
	border: 0.21em solid currentcolor;
	border-right-color: transparent;
}

.top-part {
	padding-top: 20px;
	background: linear-gradient(66deg, rgba(5,108,242,1) 0%, rgba(3,62,140,1) 62%, rgba(3,62,140,1) 94%);
	color: #ffffff;
	text-align: center;
	width: 100%;
	padding-bottom: 60px;
	border-radius: 10px 10px 0 0;
}

.bottom-part {
	position: relative;
	top: -60px;
	margin-bottom: -60px;
}

.desc {
	text-align: center;
	color: #525864;
}

.subt {
	font-size: 20px;
	font-weight: bold;
	font-family: 'Gilmer';
	text-align: center;
	margin-bottom: 10px;
}

.action-part {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 20px;
	width: 60%;
}

.action-part .btn {
	background-color: #ffffff;
	padding: 10px 20px;
	font-size: 12px;
	border-radius: 4px;
	border: 1px solid #056cf2;
	color: #056cf2;
}

.action-part .btn.blue {
	background-color: #056cf2;
	color: #fff;
}

@media screen and (max-width: 768px) {
	.round-image-container .round-image {
		max-width: 119px;
		max-height: 119px;
	}

	.match-block-container {
		width: 80%;
	}

	.action-part {
		flex-direction: column-reverse;
	}

	.action-part .btn {
		width: 100%;
		margin: 5px 0;
	}
	
}
</style>