export const useCookie = () => {
  function setCookie(key: string, value: string, maxAge = 31536000) {
    document.cookie = `${key}=${encodeURIComponent(value)}; path=/; max-age=${maxAge}`;
  }

  function getCookie(name: string) {
		const cookies: {[key: string]: string} = document.cookie.split('; ').reduce((acc: Record<string, string>, cookie) => {
			const [key, value] = cookie.split('=');
			acc[key] = decodeURIComponent(value);
			return acc;
		}, {});
		return cookies[name] ? JSON.parse(cookies[name]) : null;
	}

	function updateCookie(name: string, key: string, value: string|number|boolean ) {
		const currentData = getCookie(name) || {};
		currentData[key] = value;
		setCookie(name, JSON.stringify(currentData));
	}
	
  function deleteCookie(key: string) {
    document.cookie = `${key}=; path=/; max-age=0`;
  }

  return {
    getCookie,
		setCookie,
		updateCookie,
		deleteCookie
  };
};

export default { useCookie };
