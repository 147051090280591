import { Component, ref, h, markRaw, shallowRef } from 'vue'
import FilterHeader from '@/components/ProfilePages/Match/FilterHeader.vue'
import CounterComponent from '@/components/CustomComponents/CounterComponent.vue'

type GradientComponentEntry = {
	component: Component;
	params?: Record<string, any>;
	style?: string;
};

const isGradientVisible = ref(false)
const isGradientInsideView = ref(false)
const slots = ref<string[]>([])
const gradientComponents = shallowRef<GradientComponentEntry[]>([])
const components: Record<string, Component> = {
	FilterHeader,
	CounterComponent
}

export const useGradientBackground = () => {
	const toggleGradient = () => {
		isGradientVisible.value = !isGradientVisible.value
	}

	const isGradientContainingThisComponent = (componentsList: any) => {
		return Object.keys(componentsList).forEach((component) => slots.value.includes(component))
	}

	const addComponentsToGradient = (componentName: string | string[], params?: Record<string, any>) => {
		if (Array.isArray(componentName)) {
			componentName.forEach((component) => {
				if (components[component] && !slots.value.includes(component)) {
					slots.value.push(component);
					gradientComponents.value.push({ component: components[component], params: params?.[component] || {} });
				}
			})
			return;
		}

		if (components[componentName] && !slots.value.includes(componentName)) {
			slots.value.push(componentName);
			gradientComponents.value.push({ component: components[componentName], params: params || {} });
		}
	}

	const createAndAddComponent = (componentData: { tag: string, text: string, style: string, divStyle: string, slot: string }[], params?: Record<string, unknown>) => {
		const componentElements = componentData.map(data => {
			return h(data.tag, { style: data.style || '' }, data.text);  // Generate a tag with the corresponding text
		});

		const finalComponent = markRaw(h('div', { style: componentData[0].divStyle || '' }, componentElements));

		componentData.filter((c) => {
			if (!slots.value.includes(c.slot)) {
				slots.value.push(c.slot)
				gradientComponents.value.push({ component: finalComponent, params: params || {} });
			}
		})
	}

	const addStyleToComponent = (component: string, style: string) => {
		const targetEntry = gradientComponents.value.find(entry => entry.component === components[component]) || gradientComponents.value.find(entry => entry.params?.reference === component);
		if (targetEntry) {
			const styles = style.split(';');
			styles.forEach(style => {
				const [property, value] = style.split(':').map(str => str.trim());


				if (!targetEntry.params) {
					targetEntry.params = {}
				}

				targetEntry.params.style = targetEntry.params.style || {};

				if (targetEntry.params.style[property]) {
					targetEntry.params.style[property] = value;
				} else {
					targetEntry.params.style = { ...targetEntry.params.style, [property]: value };
				}

				targetEntry.style = transformStyleObjectToString(targetEntry.params.style);
				gradientComponents.value = [...gradientComponents.value];
			})
		} else {
			console.warn('Component not found in gradientComponents.');
		}
	}

	const transformStyleObjectToString = (styleObject: Record<string, string>) => {
		return Object.entries(styleObject).map(([property, value]) => `${property}: ${value}`).join(';');
	}

	const resetComponentsInGradient = () => {
		gradientComponents.value = []
		slots.value = []
	}

	return {
		isGradientVisible,
		isGradientInsideView,
		gradientComponents,
		createAndAddComponent,
		toggleGradient,
		isGradientContainingThisComponent,
		addComponentsToGradient,
		addStyleToComponent,
		resetComponentsInGradient
	}
}

export default { useGradientBackground }