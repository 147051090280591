<template>
  <div class="filter-container" :style="isGradientVisible ? {'position': 'absolute'} : {}">
		<div class="custom-filter-bar">
      <!-- Conditionally render filters or search input based on isSearchMode -->
      <template v-if="!isSearchMode">
        <div
          v-for="(filter, index) in filters"
          :key="index"
          :class="[
						'custom-filter-item',
						{
							'custom-filter-item-clicked': selectedFilter === index,
							'previous-hovered': previousHoveredFilter === index,
						}
					]"
          @mouseenter="openDropdown(index)"
          @mouseleave="scheduleDropdownClose"
        >
          <div class="custom-filter-header" @click="toggleFilter(index)">
            <span class="custom-filter-label">{{ filter.label }}</span>
            <button class="custom-filter-button">{{ filter.buttonText }}</button>
          </div>

          <!-- Dropdown menu for 'Where' filter -->
          <div
            v-if="isDropdownOpen(index) && filter.label === 'Where'"
            class="custom-dropdown-menu"
            @mouseenter="clearDropdownClose"
            @mouseleave="scheduleDropdownClose"
          >
            <div class="input-container">
              <input
                type="text"
                class="autocomplete-input"
                v-model="whereInput"
                placeholder="Search location"
                @input="onWhereInput"
                @keydown.down.prevent="onArrowDown"
                @keydown.up.prevent="onArrowUp"
                @keydown.enter.prevent="selectLocation"
              />
              <ul v-if="suggestions.length" class="suggestions-list">
                <li
                  v-for="(suggestion, sIndex) in suggestions"
                  :key="sIndex"
                  :class="{ 'highlighted': sIndex === selectedSuggestionIndex }"
                  @click="selectSuggestion(suggestion)"
                >
                  {{ suggestion.description }}
                </li>
              </ul>
            </div>
            <div class="slider-distance">Distance: {{ mappedValue }} km</div>
            <custom-slider 
              v-model="sliderInternalValue"
              @change="handleSliderChange"
							class="custom-slider"
            />
          </div>

          <!-- Dropdown menu for other filters -->
          <div
            v-else-if="isDropdownOpen(index)"
            class="custom-dropdown-menu"
            @mouseenter="clearDropdownClose"
            @mouseleave="scheduleDropdownClose"
          >
            <div
              v-for="(option, optIndex) in filter.options"
              :key="optIndex"
              :class="['custom-dropdown-option', { 'active': filter.selectedOptions.includes(option) }]"
              @click.stop="toggleOption(index, option)"
            >
              {{ option }}
            </div>
          </div>
        </div>
      </template>

      <!-- Search input field when isSearchMode is true -->
      <template v-else>
        <input
          type="text"
					name="search-input"
          class="custom-search-input"
          v-model="currentSearchInput"
          placeholder="Type to search..."
          @keydown.enter.prevent="addSearchTerm"
        />
      </template>

      <!-- Toggle Search Button -->
      <button class="custom-search-button" @click="handleButtonClick" name="search">
        <img
          :src="isSearchMode ? menuVectorIcon : searchIcon"
          :alt="isSearchMode ? 'Menu' : 'Search'"
        />
      </button>
    </div>

    <!-- New bar for selected filters -->
    <div class="selected-filters-bar" v-if="hasSelectedFilters">
      <div
        class="selected-filter-chip"
        v-for="(chip, index) in selectedFilterChips"
        :key="index"
      >
        {{ chip.option }}
      </div>
      <button class="clear-all-button" @click="clearAllFilters">
        <img src="@/assets/cross.svg" alt="Clear All" />
        Clear All
      </button>
    </div>
  </div>
</template>

<script>
import CustomSlider from "@/components/CustomComponents/CustomSlider.vue";
import { useGoogleMaps } from "@/composables/useGoogleMaps";

import searchIcon from "@/assets/search.svg";
import menuVectorIcon from "@/assets/filter.svg";
import { useGradientBackground } from "@/composables/useGradientBackground";
import { useVacanciesStore } from "@/store/vacancies";
import { storeToRefs } from "pinia";

export default {
  components: {
    CustomSlider,
  },
  props: {
    initialFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      sliderInternalValue: 0,
      valueMap: [0, 1, 5, 10, 20, 50, 80, 100, 150, 200],
      selectedFilter: null,
      whereInput: '',
      suggestions: [],
      selectedSuggestionIndex: -1,
      geocoder: null,
      autocompleteService: null,
      dropdownHovered: false,
      closeDropdownTimeout: null,
      filters: [],
      isSearchMode: false, 
      searchTerms: [], // Array to store search chips
      searchInput: '', // All search terms as a single string
      currentSearchInput: '', // Current input for the search term
      searchIcon, 
      menuVectorIcon,
      previousHoveredFilter: null,
    };
  },
	setup() {
		const { isGradientVisible } = useGradientBackground();
		const { filters: storeChips } = storeToRefs(useVacanciesStore())
		return { isGradientVisible, storeChips };
	},
  computed: {
    mappedValue() {
      return this.valueMap[this.sliderInternalValue];
    },
    hasSelectedFilters() {
      // Check if any filters have selected options or if there are search terms
      return (
        this.filters.some(filter => filter.selectedOptions.length > 0) ||
        this.searchInput.length > 0
      );
    },
    selectedFilterChips() {
      const chips = [];

      this.filters.forEach(filter => {
        if (filter.selectedOptions.length > 0) {
          if (filter.label === "Where") {
            const location = filter.selectedOptions[0];
            chips.push({
              label: filter.label,
              option: location.city,
            });
          } else {
            filter.selectedOptions.forEach(option => {
              chips.push({
                label: filter.label,
                option: option,
              });
            });
          }
        }
      });

      // Include search terms as chips
      this.searchTerms.forEach(term => {
        chips.push({
          label: "Search Term",
          option: term,
        });
      });

      return chips;
    },

  },
  mounted() {
    this.initializeGoogleServices();
		// Keep track of selected options during the session and show under the filter
		if(Object.keys(this.storeChips).length > 0) {
			Object.entries(this.storeChips).map(([key, item]) => {
				this.filters.forEach(filter => {
					console.log(filter.label, key)
					if(filter.label.toLowerCase() === key.toLowerCase()) {
						if(Array.isArray(item)) {
							item.forEach(i => filter.selectedOptions.push(i))
						} else {
							filter.selectedOptions.push(item)
						}
					}
				});
			})

			console.log(this.filters)
		}
  },
  created() {
    this.initializeFilters();
  },
  methods: {
    handleSliderChange() {
      this.handleSearchClick(); // Trigger the search click logic
    },
    initializeFilters() {
      // Define the default filter structure
      const defaultFilters = [
        {
          label: "Where",
          buttonText: "Search destinations",
          options: [],
          selectedOptions: [],
        },
        // {
        //   label: "Company type",
        //   buttonText: "Select Company type",
        //   options: ["Privately Held", "Public Company"],
        //   selectedOptions: [],
        // },
        {
          label: "Sector",
          buttonText: "Select one or more",
          options: ['Biotechnology',
                    'Chemical Engineering',
                    'Civil Engineering',
                    'Construction',
                    'Education',
                    'Electrical and Electronics Engineering',
                    'Energy Services',
                    'Engineering Services',
                    'Financial Services',
                    'Hospitals and Health Care',
                    'Information Services',
                    'IT Services and Consulting',
                    'Logistics and Supply Chain',
                    'Manufacturing',
                    'Materials Manufacturing',
                    'Mechanical Engineering',
                    'Oil, Gas, and Mining',
                    'Other',
                    'Professional Services',
                    'Research',
                    'Retail',
                    'Software Development'
                  ],
          selectedOptions: [],
        },
        {
          label: "Contract",
          buttonText: "Select Contract Type",
          options: ["Part-time", "Internship", "Full-time"],
          selectedOptions: [],
        },
      ];

      // Apply initial filters from the prop
      defaultFilters.forEach((filter) => {
        const filterValue = this.initialFilters[filter.label];
        if (filterValue !== undefined && filterValue !== null) {
          filter.selectedOptions = Array.isArray(filterValue)
            ? filterValue
            : [filterValue];
        } else {
          filter.selectedOptions = [];
        }
      });

      // Handle 'Where' filter separately
      const whereFilter = defaultFilters.find((f) => f.label === "Where");

      if (
        whereFilter &&
        this.initialFilters["location_long"] !== undefined &&
        this.initialFilters["location_lat"] !== undefined
      ) {
        const locationData = {
          city: this.initialFilters["location_city"] || "",
          coordinates: {
            lat: this.initialFilters["location_lat"],
            lng: this.initialFilters["location_long"],
          },
        };

        whereFilter.selectedOptions = [locationData];
        this.whereInput = locationData.city;

        // Set the slider value based on distance
        const distance = this.initialFilters["location_distance"];
        if (distance !== undefined) {
          const index = this.valueMap.indexOf(distance);
          if (index !== -1) {
            this.sliderInternalValue = index;
          } else {
            // If distance not found in valueMap, default to closest value
            this.sliderInternalValue = this.valueMap.reduce(
              (prevIndex, currValue, currIndex) => {
                return Math.abs(currValue - distance) <
                  Math.abs(this.valueMap[prevIndex] - distance)
                  ? currIndex
                  : prevIndex;
              },
              0
            );
          }
        }
      } else {
        // Ensure whereInput is empty if no location is provided
        this.whereInput = '';
      }

      // Assign the initialized filters to `this.filters`
      this.filters = defaultFilters;
    },

    async initializeGoogleServices() {
      try {
        const { initGoogleServices, autocompleteService, geocoder } = useGoogleMaps();
        await initGoogleServices();
        this.autocompleteService = autocompleteService.value;
        this.geocoder = geocoder.value;
      } catch (error) {
        console.error("Error initializing Google Maps services:", error);
      }
    },
    openDropdown(index) {
			this.previousHoveredFilter = index - 1;
      this.selectedFilter = index;
      this.clearDropdownClose();
    },
    scheduleDropdownClose() {
      this.clearDropdownClose();
      this.closeDropdownTimeout = setTimeout(() => {
				this.previousHoveredFilter = null;
				this.selectedFilter = null;
      }, 300); // 300ms delay for closing dropdown
    },
    clearDropdownClose() {
      if (this.closeDropdownTimeout) {
        clearTimeout(this.closeDropdownTimeout);
        this.closeDropdownTimeout = null;
      }
    },
    isDropdownOpen(index) {
      return this.selectedFilter === index;
    },
    toggleFilter(index) {
			this.selectedFilter = this.selectedFilter === index ? null : index;
    },
    toggleOption(filterIndex, option) {
      const filter = this.filters[filterIndex];
      const optionIndex = filter.selectedOptions.indexOf(option);
      if (optionIndex > -1) {
        filter.selectedOptions.splice(optionIndex, 1);
      } else {
        filter.selectedOptions.push(option);
      }
      this.handleSearchClick(); // Trigger search when filter option is changed
    },
    async onWhereInput() {
      if (this.whereInput.length > 2 && this.autocompleteService) {
        const request = {
          input: this.whereInput,
          types: ['(cities)'],
        };
        this.autocompleteService.getPlacePredictions(request, (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && predictions.length) {
            this.suggestions = predictions;
            this.selectedSuggestionIndex = -1;
          } else {
            this.suggestions = [];
          }
        });
      } else {
        this.suggestions = [];
      }
    },
    onArrowDown() {
      if (this.selectedSuggestionIndex < this.suggestions.length - 1) {
        this.selectedSuggestionIndex++;
      }
    },
    onArrowUp() {
      if (this.selectedSuggestionIndex > 0) {
        this.selectedSuggestionIndex--;
      }
    },
    selectLocation() {
      if (this.selectedSuggestionIndex >= 0) {
        this.selectSuggestion(this.suggestions[this.selectedSuggestionIndex]);
      }
    }, 
    async selectSuggestion(suggestion) {
      try {
        const place = await new Promise((resolve, reject) => {
          this.geocoder.geocode({ placeId: suggestion.place_id }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK && results[0]) {
              resolve(results[0]);
            } else {
              reject(new Error(`Failed to retrieve place details: ${status}`));
            }
          });
        });

        const locationData = {
          city: suggestion.description,
          coordinates: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
        };

        const filter = this.filters.find((f) => f.label === "Where");
        filter.selectedOptions = [locationData];
        this.suggestions = [];
        this.whereInput = suggestion.description;

        this.handleSearchClick();
      } catch (error) {
        console.error("Error selecting suggestion:", error);
      }
    },

    handleSearchClick() {
      const selectedFilters = {};
      this.filters.forEach((filter) => {
        if (filter.label === "Where" && filter.selectedOptions.length) {
          const location = filter.selectedOptions[0];
          selectedFilters["location_long"] = location.coordinates.lng;
          selectedFilters["location_lat"] = location.coordinates.lat;
          selectedFilters["location_distance"] = this.mappedValue;
          selectedFilters["location_city"] = this.whereInput;
        } else if (filter.selectedOptions.length > 0) {
          selectedFilters[String(filter.label).toLowerCase()] = filter.selectedOptions;
        }
      });
      this.$emit("filters-selected", selectedFilters, this.searchInput);
    },

    handleButtonClick(){
      if (this.isSearchMode) {
        // If currently in search mode, perform the search with the input
        this.$emit("search-performed", this.searchInput);
        this.isSearchMode = false;
        this.clearAllFilters()
      } else {
        // Toggle to search mode
        this.clearAllFilters()
        this.isSearchMode = true;
      }
    },

    addSearchTerm() {
      const term = this.currentSearchInput.trim();
      if (term.length > 0 && !this.searchTerms.includes(term)) {
        // Add the new term to searchTerms array
        this.searchTerms.push(term);

        // Update searchInput to include the new term
        this.searchInput = this.searchTerms.join(', ');

        // Clear current input
        this.currentSearchInput = '';
        this.handleSearchClick()
      }
    },
    clearAllFilters() {
      this.filters.forEach((filter) => {
        filter.selectedOptions = [];
      });
      this.searchTerms = []; // Clear all search terms
      this.searchInput = ''; // Clear the combined search string
      this.currentSearchInput = ''; // Clear the current input fiel
      this.whereInput = '';
      this.sliderInternalValue = 0; // Reset slider value
      this.handleSearchClick(); // Update search results

    },
    onSearchInput() {
      if (this.searchInput.length > 3){
        console.log("search-input", this.searchInput);
        this.handleSearchClick()
      }
    },
  },
};
</script>

<style scoped>
/* General box-sizing and resets */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.filter-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers content horizontally */
  width: 100%; /* Ensures full width */
}
.autocomplete-input {
  width: 100%;
  padding: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
	font-size: 12px;
}

.input-container {
	padding: 5px 10px;
}

.input-container input {
	border: none;
	border-bottom: 1px solid #ccc;
	border-radius: 0;
	outline: none;
}

.suggestions-list {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  z-index: 9000;
  /* width: 100%; */
}

.suggestions-list li {
  padding: 8px;
  cursor: pointer;
}

.suggestions-list li.highlighted {
  background-color: #f0f0f0;
}

.slider-distance {
  font-size: 12px;
  color: #555;
  margin-top: 8px;
	padding: 5px 10px;
}

.custom-slider {
	padding: 0 10px;
}

.custom-filter-bar {
  display: flex;
  align-items: center;
  gap: 0;
  width: 650px;
  height: 80px;
  background-color: #ffffff;
  border-radius: 100px;
  box-shadow:  0 4px 14px #003a8512;
  overflow: visible;
  padding-right: 10px;
}

.custom-filter-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
  height: 80px;
  width: 180px;
  text-align: left;  
  transition: background-color 0.3s ease, border-radius 0.3s ease;
}

.custom-filter-header {
  cursor: pointer;
	padding-left: 30px;
  border-right: 1px solid #ced4e0;
}

.custom-filter-item:last-of-type .custom-filter-header {
  border-right: none;
	padding-right: 0;
}

.custom-filter-item:hover {
  background-color: #DBEAFF;
  height: 80px;
  border-radius: 100px;
}

.custom-filter-item:hover .custom-filter-header,
.custom-filter-item.previous-hovered .custom-filter-header {
  border-right: none;
}

.custom-filter-label {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.custom-filter-button {
  background: none;
  border: none;
  color: #797979;
  cursor: pointer;
  text-align: left;
  transition: color 0.3s ease;
  padding: 0;
}

.custom-search-button {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 100%;
  padding: 0 10px 0 60px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.custom-search-button img {
  width: 45px;
  height: 45px;
}

/* Dropdown Menu */
.custom-dropdown-menu {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
	transform: translateX(-50%);
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 14px #003a851e;
  padding: 10px;
  z-index: 100;
  width: max-content;
	min-width: 270px;
}

.custom-dropdown-option {
  padding: 5px 10px;
  cursor: pointer;
  color: #797979;
  font-size: 12px;
}

.custom-dropdown-option.active {
  font-weight: bold;
  color: #000000;
}

.custom-dropdown-option:hover {
  background-color: #f0f0f0;
}

/* Styles for selected filters bar */
.selected-filters-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
  padding: 0 10px;
}

.selected-filters-chips {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.selected-filter-chip {
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #000;
  border-radius: 16px;
  padding: 4px 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.08);

}

.selected-filter-chip img {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  cursor: pointer;
}

.clear-all-button {
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 16px;
  padding: 4px 8px;
  cursor: pointer;
  margin-bottom: 8px;
  /* Ensure it appears after the chips */
  order: 1;
}

.clear-all-button img {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

/* Styles for the custom search input field */
.custom-search-input {
  width: 100%;
  height: 40px; /* Adjust height as needed */
  padding: 8px 12px;
  border: 1px solid #ced4e0;
  border-radius: 20px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s ease;
}

.custom-search-input:focus {
  border-color: #056cf2;
}

/* Optional: Adjust the position of the search input to match the filter bar */
.custom-filter-bar > .custom-search-input {
  margin-left: 20px; /* Adjust spacing as needed */
}
</style>
