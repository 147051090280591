import { ref } from 'vue'

const ipv4 = ref('')

export const useGetIpAddress = () => {

	const getIpAddress = async () => {
		const response = await fetch('https://api.ipify.org?format=json')
		const data = await response.json()

		ipv4.value = data.ip
	}

	return { ipv4, getIpAddress }
}

export default { useGetIpAddress }