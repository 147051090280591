<template>
	<div class="counter-container" :style="{ transform: `scale(${dynamicScale}` }">
		<div class="d-flex justify-content-between align-items-center w-50 mx-auto">
			<div class="counter-content-container">
				<span class="numbers">{{ convertBigNumbers(totalVacancies) ?? 0 }}</span>
				<span class="counter-text">Total Vacancies</span>
			</div>

			<div class="counter-content-container">
				<span class="numbers">{{ convertBigNumbers(totaEmployer) ?? 0 }}</span>
				<span class="counter-text">Total Employers</span>
			</div>

			<div class="counter-content-container">
				<span class="numbers">{{ convertBigNumbers(newVacanciesThisWeek) ?? 0 }}</span>
				<span class="counter-text">New Vacancies in last 7 days</span>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useVacanciesStore } from '@/store/vacancies';

const dynamicScale = ref('1');

const { totaEmployer, totalVacancies, newVacanciesThisWeek } = storeToRefs(useVacanciesStore());
const { fetchCounterData } = useVacanciesStore();

onMounted(() => {
	fetchCounterData();
});

const convertBigNumbers = (n: Number) => {
	if(!n) return 0;

	const numStr = String(n);
	let divisor;
	let suffix;

	if(numStr.length === 4) {
		return String(n).slice(0, 1) + ',' + String(n).split('').slice(1).join('');
	} else if (numStr.length > 4 && numStr.length <= 6) {
		divisor = 1000;
		suffix = 'K';
	} else if (numStr.length > 6 && numStr.length <= 9) {
		divisor = 1000000;
		suffix = 'M';
	} else if (numStr.length > 9) {
		divisor = 1000000000;
		suffix = 'B';
	} else {
		return n;
	}

	const number = `${(Number(n) / divisor).toFixed(1)} ${suffix}`;

	if (number.includes('.00')) {
		return number.replace('.00', '');
	}

	return number;
}
</script>

<style scoped>
.numbers {
	font-size: 36px;
	font-family: 'Gilmer-Medium', 'sans-serif';
	padding: 0 10px;
	border-right: 2px solid #95C3FF;
	text-align: center;
}

.counter-text {
	font-size: 12px;
	font-family: 'Inter', 'sans-serif';
	font-weight: 500;
	padding-left: 20px;
}

.numbers,
.counter-text {
	color: #ffffff;
	vertical-align: middle;
}

.counter-content-container {
	display: inline-block;
}

.counter-container {
	margin: 0 auto;
	margin-top: 40px;
}

@media screen and (max-width: 1520px) {
	.counter-content-container {
		display: flex;
		flex-direction: column;
		align-items: end;
		border-right: 2px solid #95C3FF;
	}

	.numbers {
		border: none;
	}

	.counter-text {
		padding: 0 10px;
	}
}
</style>