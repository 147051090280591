// store/utils.js
import { v4 as uuidv4 } from 'uuid';
import defaultImage from '@/assets/profile.png';

/**
 * Assigns unique IDs to items that lack an 'id' property.
 * @param {Array} items - The array of items (skills or softwarePackages).
 * @returns {Array} - The updated array with IDs assigned.
 */
export function assignIdsToItems(items: Record<string, unknown>[]): Record<string, unknown>[] {
  return items.map(item => {
    if (!item.id) {
      return { ...item, id: uuidv4() };
    }
    return item;
  });
}

export function convertImageToBase64(imageFile: File) {
	return new Promise((resolve, reject) => {
		if (!(imageFile instanceof File)) {
      reject(new Error("Input must be a File"));
      return;
    }
		
		const reader = new FileReader();

		reader.onloadend = () => {
			const result = reader.result;
      if (typeof result === "string") {
        const base64Data = result.split(",")[1];
        resolve(base64Data);
      } else {
        reject;
      }
		};
		reader.onerror = reject;
		
		reader.readAsDataURL(imageFile);
	});
}

export function handleImageError(event: any) {
	event.target.src = defaultImage;
}