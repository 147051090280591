<template>
	<div class="bg-overlay" v-if="notificationVisible && notification">
		<div :class="isOverlayVisible ? 'notification-overlay-sidebar' : 'notification-overlay'">
			<button v-if="showCloseButton" class="close-btn" @click="close">
				<i class="fa-regular fa-x"></i>
			</button>
			<div class="notification-content mt-4">
				<img :src="notification.svg" alt="notification-icon" class="notification-icon" />
				<span class="notification-title" v-html="notification.title"></span>
				<span class="notification-text" v-html="notification.text"></span>
				<div class="button-container-notification">
					<button v-if="notification.buttonText" class="action-btn" @click="handleAction">
						{{ notification.buttonText }}
					</button>
					<button v-else-if ="showCloseButton" class="close-btn-large" @click="close">Close window</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useNotification } from '@/composables/useNotification';
import { useGlobalStore } from '@/store/store';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const { notificationVisible, notifications, showCloseButton, removeNotification } = useNotification();
const { isOverlayVisible } = storeToRefs(useGlobalStore());

const notification = computed(() => notifications.value[0]);

const handleAction = () => {
	if (notification.value?.action) {
		notification.value.action();
	}
	removeNotification();
}

const close = () => {
	removeNotification();
}
</script>


<style scoped>
.notification-overlay {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 20px;
	width: 360px;
	/* Set a fixed width */
	max-width: 80%;
	/* Optional: Ensure it is responsive on smaller screens */
	z-index: 6001;
	text-align: center;

}

.notification-overlay-sidebar {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(calc(-50% + 175px) , -50%);
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 20px;
	width: 360px;
	/* Set a fixed width */
	max-width: 80%;
	/* Optional: Ensure it is responsive on smaller screens */
	z-index: 6001;
	text-align: center;

}

.notification-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	max-width: 80%;
}

.notification-icon {
	width: 56px;
	height: 56px;
	margin-bottom: 10px;
}

.notification-text {
	margin-top: 10px;
	font-size: 14px;
	font-family: "Inter";
}

.notification-title {
	font-size: 28px;
	font-weight: bold;
	font-family: "Gilmer";
}

.close-btn {
	position: absolute;
	top: 20px;
	right: 20px;
	background: none;
	border: none;
	font-size: 16px;
	line-height: 1;
	cursor: pointer;
	color: #7D8AA6;
}

.logo-content {
	display: flex;
	align-items: center;
	position: absolute;
	top: 30px;
	left: 50%;
	transform: translateX(-50%);
}
.lionhead-text {
  margin-left: 10px;
  font-family: 'Gilmer', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  color: #0d0e10;
}

.close-btn-large {
	background-color: rgb(5, 108, 242);
	color: #fff;
	padding: 10px 20px;
	border-radius: 4px;
	margin-top: 15px;
}

.bg-overlay {
	width: 100%;
	height: 100vh;
	background: rgba(5, 108, 242, .6);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2000
}

@media screen and (max-width: 768px) {
	.notification-overlay {
		width: 80%;
		max-width: 80%;
	}

	.notification-overlay-sidebar {
		width: 80%;
		max-width: 80%;
		transform: translate(-50%, -50%);
	}
}

.button-container-notification {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 15px;
}

.action-btn {
	background-color: rgb(5, 108, 242);
	color: #fff;
	padding: 10px 20px;
	border-radius: 4px;
}
</style>
