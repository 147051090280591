import values_json from '@/assets/values.json';
import { useVacanciesStore } from '@/store/vacancies'; // Example of other Pinia stores if needed
import { useSkillsStore } from '@/store/skills';
import { useSoftwareStore } from '@/store/software';
import { useProfileStore } from '@/store/profile';
import router from '@/router/index.js'
import { auth } from '@/firebase';
import apiClient from '@/axios';
import { trackEvent } from '@/services/analytics';
import * as type from '@/types/'
import { User } from 'firebase/auth';
import { defineStore } from 'pinia';
import { computed, ref, onMounted, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { usePopup } from '@/composables/usePopup';
import { convertImageToBase64 } from './utils';
import { useCookie } from '@/composables/useCookie';

// @ts-ignore
import profilePic from '@/assets/profile.png';

function calculateMonthsBetweenDates(start: string, end: string) {
	const startDate = new Date(start);
	const endDate = end ? new Date(end) : new Date(); // If end date is not specified, use current date
	return (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
}
function getCurrentDate() {
	return new Date();
}
function getOverlapMonths(start1: string, end1: string, start2: string, end2: string) {
	const startDate1 = new Date(start1);
	const endDate1 = end1 ? new Date(end1) : new Date();
	const startDate2 = new Date(start2);
	const endDate2 = end2 ? new Date(end2) : new Date();

	const overlapStart = new Date(Math.max(startDate1.getTime(), startDate2.getTime()));
	const overlapEnd = new Date(Math.min(endDate1.getTime(), endDate2.getTime()));

	if (overlapStart > overlapEnd) {
		return 0;
	}

	return (overlapEnd.getFullYear() - overlapStart.getFullYear()) * 12 + (overlapEnd.getMonth() - overlapStart.getMonth());
}

function filterOverlappingExperience(experiences: type.Experience[]) {
	const sortedExperiences = experiences.filter(exp => !exp.deleted).sort((a, b) => new Date(a.dateFrom).getTime() - new Date(b.dateFrom).getTime());
	const filteredExperiences = [];

	for (let i = 0; i < sortedExperiences.length; i++) {
		const currentExp = sortedExperiences[i];
		let overlapFound = false;

		for (let j = 0; j < filteredExperiences.length; j++) {
			const filteredExp = filteredExperiences[j];
			const currentFrom = currentExp.dateFrom ? new Date(currentExp.dateFrom).toLocaleDateString() : '';
			const currentTo = currentExp.dateTo ? new Date(currentExp.dateTo).toLocaleDateString() : '';
			const filteredFrom = filteredExp.dateFrom ? new Date(filteredExp.dateFrom).toLocaleDateString() : '';
			const filteredTo = filteredExp.dateTo ? new Date(filteredExp.dateTo).toLocaleDateString() : '';

			if (getOverlapMonths(currentFrom, currentTo, filteredFrom, filteredTo) > 0) {
				overlapFound = true;
				break;
			}

		}

		if (!overlapFound) {
			filteredExperiences.push(currentExp);
		}
	}

	return filteredExperiences;
}

export const useGlobalStore = defineStore( 'global', () => {
	console.log('Initializing global store');  // Log store initialization

	const experiences = ref([] as type.Experience[])
	const linkedinSkills= ref<Record<string, unknown>[]>([])
	const personalInfo= ref({
		fullName: '',
		location: null,
		title: '',
		summary: '',
		phone: '06123456789',
		email: 'John.Doe@lionhead.works'
	} as type.PersonalInfo)
	const isMobileDevice= ref(true)
	const isMobileDeviceDetected= ref(false)
	const previousPage= ref('')
	const education= ref([] as type.Education[])
	const personalValues = ref<string[]>([])
	const formData= ref([])
	const selectedValues= ref<string[]>([])
	const selectedSummaryValues= ref<string[]>([])
	const passion= ref('Passionate about extreme ironing, competitive cheese rolling, and underwater basket weaving. Avid collector of novelty rubber ducks and an enthusiastic participant in llama yoga. When not perfecting my skills in interpretive dance with houseplants, I enjoy hosting dinosaur-themed tea parties and volunteering as a professional hide-and-seek champion at local libraries. Also, a proud member of the international jellybean flavor taste-testing committee.')
	const downloadTriggered= ref(false)
	const isSpecialUser= ref(false)
	const isLoggingOut= ref(false)
	const isLoggingIn= ref(false)
	const ambitionFormCompleted= ref(false)
	const lastPageIndex= ref(0)
	const matchOverlayShown= ref(false)
	const isOverlayVisible= ref(false)
	const editSidebarVisible= ref(false)
	const editSidebarSection= ref()
	const summaryIndexRemoved = ref(0)
	const areValuesShownOnSummary = ref(true)
	const areAmbitionsShownOnSummary = ref(true)
	const areSkillsShownOnSummary = ref(true)
	const arePassionsShownOnSummary = ref(true)
	const areEducationShownOnSummary = ref(true)
	const areExperiencesShownOnSummary = ref(true)
	const isSummaryShown = ref(true)
	const isAuthenticated = computed(() => auth.currentUser !== null)
	const attempts = ref<number>(0);
	const maxAttempts = ref<number>(3);
	const maxLimitReached = ref(false)
	const showMatchOveraly = ref(false)
	const improveProfileVisible = ref(false)
	const isVideoPopupVisible = ref(false)
	const videoUrl = ref('')

	const { setSkills } = useSkillsStore()
	const { setSoftware } = useSoftwareStore()
	const { setVacanciesState, setVacancies } = useVacanciesStore()
	const { fetchAndUploadProfilePicture, manageFallback } = useProfileStore()

	const { profilePictureUrl } = storeToRefs(useProfileStore())
	const { setTexts, registerPopup, setClasses, setButtonFn, closePopup } = usePopup()
	const { skills } = storeToRefs(useSkillsStore())
	const { vacancies, vacanciesByPage, bookmarkedVacancies, selectedVacancyId, filters, currentPage, totalJobsAvailableByFilter } = storeToRefs(useVacanciesStore())
	const { softwarePackages } = storeToRefs(useSoftwareStore())
	const { deleteCookie, getCookie } = useCookie()

	const uniqueSortedExperiences = computed(() => {
		const filteredExperiences = experiences.value
		filteredExperiences.forEach((exp) => {
			if(exp?.location && exp.location.city && exp.location.province && exp.location.country && !exp?.location?.formattedAddress) {
				exp.location.formattedAddress = `${exp.location.city}, ${exp.location.province}, ${exp.location.country}`
			} 
		})
		const unique = filteredExperiences.reduce((acc: type.Experience[], current: type.Experience) => {
			const duplicate = acc.find((item) =>
				item.companyName === current.companyName &&
				item.title === current.title &&
				item.dateFrom === current.dateFrom);
			if (!duplicate) {
				acc.push(current);
			}
			return acc;
		}, []);
		return unique.sort((a, b) => {
			const startDateA = a.dateFrom ? new Date(a.dateFrom) : new Date();
			const startDateB = b.dateFrom ? new Date(b.dateFrom) : new Date();

			// If title or company is empty, move it to the end of the list
			if (a.title === '') return 1;  // a goes to the end
			if (b.title === '') return -1; // b goes to the end

			if (startDateA > startDateB) return -1;
			if (startDateA < startDateB) return 1;
			return 0;
		});
	})

		const uniqueSortedEducations = computed(() => {
			const filteredEducations = education.value
			const unique = filteredEducations.reduce((acc: type.Education[], current: type.Education) => {
				const duplicate = acc.find(item =>
					item.title === current.title &&
					item.subtitle === current.subtitle &&
					item.dateFrom === current.dateFrom);
				if (!duplicate) {
					acc.push(current);
				}
				return acc;
			}, []);
			return unique.sort((a, b) => {
				const startDateA = a.dateFrom ? new Date(a.dateFrom) : new Date();
				const startDateB = b.dateFrom ? new Date(b.dateFrom) : new Date();

				if (a.title === '') return 1;  // a goes to the end
				if (b.title === '') return -1; // b goes to the end

				if (startDateA > startDateB) return -1;
				if (startDateA < startDateB) return 1;
				return 0;
			});
		})

		const summarySortedExperiences = computed(() => {
			const filteredExperiences = experiences.value
			const unique = filteredExperiences.reduce((acc: type.Experience[], current: type.Experience) => {
				const duplicate = acc.find((item) =>
					item.companyName === current.companyName &&
					item.title === current.title &&
					item.dateFrom === current.dateFrom);
				if (!duplicate) {
					acc.push(current);
				}
				return acc;
			}, []);
			return unique.sort((a, b) => {
				if(!a.preferredIndex && !b.preferredIndex) {
					const startDateA = a.dateFrom ? new Date(a.dateFrom) : new Date();
					const startDateB = b.dateFrom ? new Date(b.dateFrom) : new Date();

					if (a.title === '') return 1;  // a goes to the end
					if (b.title === '') return -1; // b goes to the end

					if (startDateA > startDateB) return -1;
					if (startDateA < startDateB) return 1;
					return 0;
				} else {
					return (a.preferredIndex as number) - (b.preferredIndex as number);
				}
			});
		})

		const summarySortedEducations = computed(() => {
			const filteredEducations = education.value
			const unique = filteredEducations.reduce((acc: type.Education[], current: type.Education) => {
				const duplicate = acc.find(item =>
					item.title === current.title &&
					item.subtitle === current.subtitle &&
					item.dateFrom === current.dateFrom);
				if (!duplicate) {
					acc.push(current);
				}
				return acc;
			}, []);
			return unique.sort((a, b) => {
				if(!a.preferredIndex && !b.preferredIndex) {
					const startDateA = a.dateFrom ? new Date(a.dateFrom) : new Date();
					const startDateB = b.dateFrom ? new Date(b.dateFrom) : new Date();

					if (a.title === '') return 1;  // a goes to the end
					if (b.title === '') return -1; // b goes to the end

					if (startDateA > startDateB) return -1;
					if (startDateA < startDateB) return 1;
					return 0;
				} else {
					return (a.preferredIndex as number) - (b.preferredIndex as number);
				}
			});
		})

		const totalExperienceYears = computed(() => {
			const filteredExperiences = filterOverlappingExperience(experiences.value);
			const totalExperienceMonths = filteredExperiences.reduce((total, exp) => {
				const dateFrom = new Date(exp.dateFrom);
				const dateTo = !exp.dateTo ? getCurrentDate() : new Date(exp.dateTo);

				if (dateFrom && dateTo) {
					const monthsBetween = calculateMonthsBetweenDates(dateFrom.toISOString().split('T')[0], dateTo.toISOString().split('T')[0]);
					if (isNaN(monthsBetween)) {
					} else {
						return total + monthsBetween;
					}
				}

				return total; // Skip if dates are invalid
			}, 0);


			const totalEducationOverlapMonths = education.value.reduce((total, edu) => {
				const eduDateFrom = new Date(edu.dateFrom);
				const eduDateTo = !edu.dateTo ? getCurrentDate() : new Date(edu.dateTo);

				if (!edu.deleted && eduDateFrom && eduDateTo) {
					return total + filteredExperiences.reduce((expTotal, exp) => {
						const expDateFrom = new Date(exp.dateFrom);
						const expDateTo = !exp.dateTo ? getCurrentDate() : new Date(exp.dateTo);

						if (expDateFrom && expDateTo) {
							const overlapMonths = getOverlapMonths(expDateFrom.toISOString().split('T')[0], expDateTo.toISOString().split('T')[0], eduDateFrom.toISOString().split('T')[0], eduDateTo.toISOString().split('T')[0]);
							if (isNaN(overlapMonths)) {
								// console.error(`Invalid overlap months between experience ${expDateFrom} - ${expDateTo} and education ${eduDateFrom} - ${eduDateTo}:`, overlapMonths);
							} else {
								// console.log(`Overlap: Experience from ${expDateFrom} to ${expDateTo} overlaps with Education from ${eduDateFrom} to ${eduDateTo}, Overlap Months: ${overlapMonths}`);
								return expTotal + overlapMonths;
							}
						}
						return expTotal;
					}, 0);
				}
				return total; // Skip if dates are invalid or education is deleted
			}, 0);

			if (isNaN(totalExperienceMonths)) {
				console.error("Total experience months is NaN. Check the data for invalid entries.");
			}
			if (isNaN(totalEducationOverlapMonths)) {
				console.error("Total education overlap months is NaN. Check the data for invalid entries.");
			}

			const totalExperienceYears = totalExperienceMonths / 12;
			const totalEducationOverlapYears = totalEducationOverlapMonths / 12;

			const finalYears = totalExperienceYears - totalEducationOverlapYears;
			return finalYears;
		})

		const saveAll = () => {
			console.log("Saving all data..."); 
		}

		const checkLogin = async() => {
			try {
				window.dataLayer = window.dataLayer || [];
				console.log("Starting checkLogin action");

				// Wrap onAuthStateChanged in a Promise
				const currentUser: User | null = await new Promise((resolve, reject) => {
					console.log("Waiting for auth state change...");
					const unsubscribe = auth.onAuthStateChanged(
						(user) => {
							unsubscribe();
							resolve(user);
						},
						(error) => {
							console.error("Error in onAuthStateChanged:", error);
							reject(error);
						}
					);
				});

				window.dataLayer.push({
					event: "userDataAvailable",
					userId: currentUser ? currentUser.uid : null,
				});

				if (currentUser) {
					const idToken = await currentUser.getIdToken();

					const response = await apiClient.get("/check_login", {
						headers: {
							Authorization: `Bearer ${idToken}`,
						},
					});

					if (response.data.authenticated) {
						const stateResponse = await apiClient.get("/load_state", {
							headers: {
								Authorization: `Bearer ${idToken}`,
							},
						});

						if (stateResponse.data) {
							if ("isSpecialUser" in stateResponse.data) {
								isSpecialUser.value = stateResponse.data.isSpecialUser;
							}

							if (stateResponse.data.state) {
								const newState = stateResponse.data.state;
								await setStateAndInitialize(newState);
							}

							if (stateResponse.data.profilePictureUrl) {
								profilePictureUrl.value = stateResponse.data.profilePictureUrl;
							}
						}
					} else {
						console.error("User is not authenticated.");
					}
				} else {
					const profileState = localStorage.getItem('profileState')
						? JSON.parse(localStorage.getItem('profileState') as string)
						: null;

					if(profileState) {
						const state = profileState;
						profilePictureUrl.value = state.profilePictureUrl;
						delete state.profilePictureUrl;
						setState(state);
					}
					console.error("User not logged in to Firebase.");
				}
			} catch (error) {
				console.error("Failed to check login status:", error);
			}
		}

		const setOverlayVisibility = (payload: boolean) => {
			isOverlayVisible.value = payload;
		}

		const setOverlayShown = (value: boolean) => {
			matchOverlayShown.value = value;
		}


		const logout = async() => {
			try {
				console.log("Attempting to log out...");
				await resetAllStates()
				isLoggingOut.value = true;
				router.push({ name: 'Login' });

				setTexts({
					popupText: `
						Your profile gives you access to permanent opportunities. Log in for free to access all Lionhead services and matching jobs.
					`,
					// secondaryText: `As a Lionhead member, you stay anonymous, and your information is never shared without your consent, giving you control over your career without interference from recruiters who often lack the expertise to truly understand your unique skills and capabilities."`,
					textButton: `Create free account`
				})
				setClasses({
					buttonClasses: 'w-100',
				})
				registerPopup.value = true

				setButtonFn(() => {
					router.push({name: 'Register'})
					closePopup()
				})

				await auth.signOut();
				console.log("Successfully logged out.");


				sessionStorage.clear();
				localStorage.clear();

				// Push to dataLayer
				trackEvent('logout', {});

			} catch (error) {
				console.error("Logout failed:", error);
			} finally {
				isLoggingOut.value = false;
			}
		}

		function updateSummary(summary: string) {
			personalInfo.value.summary = summary
			const profileState = localStorage.getItem('profileState')
				? JSON.parse(localStorage.getItem('profileState') as string)
				: null;

			if(!isAuthenticated.value && profileState) {
				profileState.summary = summary;
				localStorage.setItem('profileState', JSON.stringify(profileState));
			} else {
				apiClient.post('/save_state', {
					partialState: {
						'personalInfo.summary': summary
					}
				})
			}

			// Track the event
			trackEvent('summary_updated', {});
		}
		function addNewEducation (educationData: type.Education) {
			education.value.push(educationData as type.Education);

			// Track the event
			trackEvent('education_added', {
				educationId: educationData.id,
				educationTitle: educationData.title,
			});
		}
		function saveEducation(educationArray: type.Education[]) {
			education.value = educationArray;

			// Track the event
			trackEvent('education_saved', {
				totalEducations: educationArray.length,

			});
		}
		function updateEducation(updatedEducation: type.Education) {
			const index = education.value.findIndex(education => education.id === updatedEducation.id);
			if (index !== -1) {
				education.value.splice(index, 1, updatedEducation);
			}

			// Track the event
			trackEvent('education_updated', {
				educationId: updatedEducation.id,
				updatedFields: Object.keys(updatedEducation),
			});
		}
		function removeEducation(educationId: number) {
			education.value = education.value.filter(edu => edu.id !== educationId);
			// Optionally, track the event
			trackEvent('education_removed', { educationId });
		}

		function addNewExperience(newExperience: type.Experience) {
			experiences.value.push(newExperience);

			// Track the event
			trackEvent('experience_added', {
				experienceId: newExperience.id,
				companyName: newExperience.companyName,
			});
		}

		function saveExperience(experienceArray: type.Experience[]) {
			experiences.value = experienceArray;
			// Track the event
			trackEvent('experience_saved', {});
		}

		function updateExperience(updatedExperience: type.Experience) {
			const index = experiences.value.findIndex(exp => exp.id === updatedExperience.id);
			if (index !== -1) {
				experiences.value.splice(index, 1, updatedExperience);
			}

			// Track the event
			trackEvent('experience_updated', {
				experienceId: updatedExperience.id,
				updatedFields: Object.keys(updatedExperience),

			});
		}
		function removeExperience(experienceId: string) {
			experiences.value = experiences.value.filter(exp => exp.id !== experienceId);

			trackEvent('experience_removed', { experienceId });
		}

		async function downloadCV() {
			try {
				downloadTriggered.value = true;
				
				const response = await apiClient.post('/generate-pdf', 
					{ url: window.location.href },
					{
						responseType: 'blob',
						headers: {
							'Authorization': `Bearer ${await auth.currentUser?.getIdToken()}`
						}
					}
				);
				
				console.log('Response from server:', response);
				console.log('Response type:', response.data?.constructor?.name);
				console.log('Response data:', response.data);
				
				// Track the event
				trackEvent('cv_downloaded', {});
				
				return response.data;
			} catch (error) {
				console.error('Store: Error downloading CV:', error);
				throw error;
			} finally {
				downloadTriggered.value = false;
			}
		}
		
		function resetDownloadTrigger() {
			downloadTriggered.value = false;
		}

		function savePersonalInfo(updatedInfo : type.PersonalInfo) {
			if (personalInfo.value) {
				personalInfo.value = {
					...personalInfo.value,
					...updatedInfo
				};
			} else {
				personalInfo.value = updatedInfo;
			}
			trackEvent('personal_info_updated', {});
		}
		function savePassion(newPassion: string) {
			passion.value = newPassion;
			trackEvent('passion_updated', {});
		}
		function setPreviousPage(page: string) {
			previousPage.value = page;
		}
		function completeAmbitionForm() {
			ambitionFormCompleted.value = true;
			trackEvent('ambition_form_completed', {});
		}

		function toggleValue(value: string) {
			const isSelecting = !selectedValues.value.includes(value);
			const index = selectedValues.value.indexOf(value);
			if (index === -1) {
				selectedValues.value.push(value);
			} else {
				selectedValues.value.splice(index, 1);
			}


			// Determine the total selected after the toggle
			const totalSelected = isSelecting ? selectedValues.value.length + 1 : selectedValues.value.length - 1;

			// Track the event
			trackEvent('value_toggled', {
				value: value,
				action: isSelecting ? 'selected' : 'deselected',
				totalSelected: totalSelected,
			});
		}

		function toggleSummaryValue(value: string) {
			const isSelecting = !selectedSummaryValues.value.includes(value);
			const index = selectedSummaryValues.value.indexOf(value);
			if (index === -1) {
				selectedSummaryValues.value.push(value);
			} else {
				selectedSummaryValues.value.splice(index, 1);
			}

			// Determine the total selected after the toggle
			const totalSelected = isSelecting ? selectedSummaryValues.value.length + 1 : selectedSummaryValues.value.length - 1;

			// Track the event
			trackEvent('value_toggled', {
				value: value,
				action: isSelecting ? 'selected' : 'deselected',
				totalSelected: totalSelected,
			});
		}

		function toggleTypesVisiblityOnSummary(type: string) {
			switch (type) {
				case 'values':
					areValuesShownOnSummary.value = !areValuesShownOnSummary.value;
					break;
				case 'ambitions':
					areAmbitionsShownOnSummary.value = !areAmbitionsShownOnSummary.value;
					break;
				case 'skills':
					areSkillsShownOnSummary.value = !areSkillsShownOnSummary.value;
					break;
				case 'passions':
					arePassionsShownOnSummary.value = !arePassionsShownOnSummary.value;
					break;
				case 'education':
					areEducationShownOnSummary.value = !areEducationShownOnSummary.value;
					break;
				case 'experiences':
					areExperiencesShownOnSummary.value = !areExperiencesShownOnSummary.value;
					break;
				case 'summary':
					isSummaryShown.value = !isSummaryShown.value;
					break;
				
				default:
					console.warn(`Type '${type}' not recognized.`);
					break;
			}
		}

		// Action to set selected values (if needed)
		const setSelectedValues = (values: string[]) => {
			selectedValues.value = values;

			// Track the event
			trackEvent('selected_values_set', {
				selectedValues: values,
			});
		}

		async function updateRecordImage(data: Record<string, File | string | number>) {
			if(!data) return
			const recordId = data.recordId as string
			const recordType = data.recordType as string
			const image = data.image as File

			if (!recordId || !recordType || !image) {
        throw new Error("Missing required fields: recordId, recordType, or image.");
    	}

			// Convert the image to Base64
			const base64Image = await convertImageToBase64(image);

			// Create the JSON payload
			const jsonPayload = {
				recordId: recordId,
				recordType: recordType,
				image: base64Image
			};

			try {
				const response = await apiClient.post('/update_record_image', jsonPayload, {});
				
				return response.data;
			} catch (error) {
				throw new Error(`Error updating record image: ${error}`);
			}
		}

		async function updateAmbitionVisibilities(data: Record<string, boolean>) {
			const profileState = localStorage.getItem('profileState')
				? JSON.parse(localStorage.getItem('profileState') as string)
				: null;

			if(!isAuthenticated.value && profileState) {
				profileState.formData = data;
				localStorage.setItem('profileState', JSON.stringify(profileState));
			} else if (Array.isArray(formData.value)) {
				await apiClient.post('/save_state', {
					partialState: {
						'formData': {}
					}
				});
			} else {
				const response = await apiClient.post('/save_state', {
					partialState: {
						'formData.visibilitiesOnSummary': { ...data }
					}
				});
				return response.data;
			}
		}

		async function saveSelectedValues() {
			const profileState = localStorage.getItem('profileState')
				? JSON.parse(localStorage.getItem('profileState') as string)
				: null;

			if(!isAuthenticated.value && profileState) {
				profileState.selectedValues = selectedValues.value;
				localStorage.setItem('profileState', JSON.stringify(profileState));
			} else {
				const response = await apiClient.post('/save_state', {
					partialState: {
						selectedValues: selectedValues.value
					}
				});
				return response.data;
			}
		}

		async function saveSelectedValuesVisibility(data: Record<string, unknown>) {
			const partialState = {
				...data
			}
			const profileState = localStorage.getItem('profileState')
				? JSON.parse(localStorage.getItem('profileState') as string)
				: null;

			if(!isAuthenticated.value && profileState) {
				const key = Object.keys(data)[0];
				profileState[key] = data[key];
				localStorage.setItem('profileState', JSON.stringify(profileState));
			} else {
				const response = await apiClient.post('/save_state', {
					partialState
				});
				return response.data;
			}
		}

		// Action to load personal values
		const loadPersonalValues = () => {
			personalValues.value = values_json.values;
		}
		async function setStateAndInitialize(newState: Record<string, string>) {
			if(getCookie('userTracking')){
				deleteCookie('userTracking')
			}
			try {
			 	setState(newState);

			} catch (error) {
				console.error("Error during initializing state:", error);
			}
		}

		const setMobileDevice = (isMobile: boolean) => {
			isMobileDevice.value = isMobile;
		}

		function setState(newState: Record<string, any>) {
			const globalState: {[key: string | number]: any} = useGlobalStore();
			
			Object.keys(newState).forEach(key => {
				if (key === 'vacancies') {
					// Use the vacancies store to set state
					setVacanciesState(newState.vacancies);
				} else if (key === 'skills') {
					const skillsData = Array.isArray(newState.skills) ? newState.skills : newState.skills.skills;
					setSkills(skillsData);
				} else if (key === 'software') {
					const softwarePackages = newState.software.softwarePackages || newState.software;
					setSoftware(softwarePackages);
				} else if (key in globalState) {
					if (typeof globalState[key] === 'object' && !Array.isArray(newState[key])) {
						globalState[key] = { ...globalState[key], ...newState[key] };
					} else {
						globalState[key] = newState[key];
					}
				} else {
					console.warn(`Key '${key}' does not exist in the state.`);
				}
			});
		}

		async function setExperiences(experiencesToSet: type.Experience[]) {
			experiences.value = experiencesToSet.map(exp => ({
				...exp,
				id: exp.id || uuidv4()
			}));
		}

		async function setEducations(educationsToSet: type.Education[]) {
			education.value = educationsToSet.map(edu => ({
				...edu,
				id: edu.id || uuidv4()
			}));
		}

		async function setLinkedinSkills(skillsToSet: Record<string, unknown>[]) {
			linkedinSkills.value = skillsToSet;
		}
	
		// Add event listener setup
		onMounted(() => {
			console.log('Setting up setFetchedData event listener in global store');
			const eventHandler = async (event: CustomEvent) => {
				console.log('Received setFetchedData event with data:', event.detail);
				await setFetchedData(event.detail);
				
				// After setting the data, update localStorage
				const profileState = {
					skills: skills.value,
					softwarePackages: softwarePackages.value,
					experiences: experiences.value,
					education: education.value,
					linkedinSkills: linkedinSkills.value,
					formData: formData.value,
					personalInfo: personalInfo.value,
					profilePictureUrl: profilePictureUrl.value
				};
				localStorage.setItem('profileState', JSON.stringify(profileState));
				console.log('Profile state updated in localStorage');
			};

			window.addEventListener('setFetchedData', eventHandler as EventListener);
			console.log('Event listener setup complete');

			// Store the handler for cleanup
			(window as any).__setFetchedDataHandler = eventHandler;
		});

		onUnmounted(() => {
			console.log('Cleaning up setFetchedData event listener');
			if ((window as any).__setFetchedDataHandler) {
				window.removeEventListener('setFetchedData', (window as any).__setFetchedDataHandler as EventListener);
				delete (window as any).__setFetchedDataHandler;
			}
		});

		async function setFetchedData(data: Record<string, any>){
			try {
				console.log('Starting to set fetched data:', data);
				await setSkills(data.skills);
				await setSoftware(data.softwarePackages);

				// Ensure experiences have IDs
				const experiencesWithIds = (data.experiences || []).map((exp: type.Experience) => ({
					...exp,
					id: exp.id || uuidv4()
				}));
				await setExperiences(experiencesWithIds);

				// Ensure education entries have IDs
				const educationWithIds = (data.education || []).map((edu: type.Education) => ({
					...edu,
					id: edu.id || uuidv4()
				}));
				await setEducations(educationWithIds);

				await setLinkedinSkills(data.linkedinSkills);

				if (data.formData) {
					formData.value = data.formData;
				}

				if (data.personalInfo) {
					personalInfo.value = {
						...personalInfo.value,
						...data.personalInfo,
						phone: data.personalInfo.phone || '06123456789',
						email: data.personalInfo.email || 'John.Doe@lionhead.works',
					};
				} else {
					if(['phone', 'email'].some(key => !personalInfo.value[key])) {
						personalInfo.value = {
							...personalInfo.value,
							phone: '06123456789',
							email: 'John.Doe@lionhead.works',
						};
					}
				}
				if (data.profilePictureUrl) {
					if(isAuthenticated.value) {
						await fetchAndUploadProfilePicture(data.profilePictureUrl, true);
					}
					else {
						profilePictureUrl.value = await manageFallback(data.profilePictureUrl)
					};

					if (sessionStorage.getItem('tmpProfileImage')) {
						sessionStorage.removeItem('tmpProfileImage')
					}
				} else {
					profilePictureUrl.value = profilePic
				}
				console.log('Finished setting fetched data');
			} catch (error) {
				console.error('Error setting fetched data:', error);
				throw error; // Re-throw to help with debugging
			}
		}

		function openSidebar(section: Record<string, unknown>) {
			editSidebarVisible.value = true;
			editSidebarSection.value = section;
			// Reset the selectedSummaryValues if the Summary component is not active
			selectedSummaryValues.value.splice(0, selectedSummaryValues.value.length)
			summaryIndexRemoved.value = 0
		}

		function closeSidebar() {
			editSidebarVisible.value = false;
			editSidebarSection.value = '';
		}

		async function resetAllStates() {
			console.log('resetting')
			experiences.value = [];
			education.value = [];
			linkedinSkills.value = [];
			personalInfo.value = {
				fullName: '',
				location: null,
				title: '',
				summary: '',
				phone: '06123456789',
				email: ''
			};
			formData.value = [];
			selectedValues.value = [];
			selectedSummaryValues.value = [];
			passion.value = '';
			downloadTriggered.value = false;
			isSpecialUser.value = false;
			isLoggingOut.value = false;
			isLoggingIn.value = false;
			ambitionFormCompleted.value = false;
			lastPageIndex.value = 0;
			matchOverlayShown.value = false;
			isOverlayVisible.value = false;
			editSidebarVisible.value = false;
			editSidebarSection.value = '';
			summaryIndexRemoved.value = 0;
			areValuesShownOnSummary.value = true;
			areAmbitionsShownOnSummary.value = true;
			areSkillsShownOnSummary.value = true;
			arePassionsShownOnSummary.value = true;
			areEducationShownOnSummary.value = true;
			areExperiencesShownOnSummary.value = true;
			isSummaryShown.value = true;
			profilePictureUrl.value = profilePic;
			skills.value = [];
			vacancies.value = [];
			vacanciesByPage.value = [];
			bookmarkedVacancies.value = [];
			selectedVacancyId.value = '';
			filters.value = {};
			currentPage.value = 0,
    	totalJobsAvailableByFilter.value = [],
			softwarePackages.value = []
		}

		function showVideoPopup(url: string) {
			videoUrl.value = url
			isVideoPopupVisible.value = true
		}

		function closeVideoPopup() {
			isVideoPopupVisible.value = false
		}

		return {
			experiences,
			linkedinSkills,
			personalInfo,
			isMobileDevice,
			isMobileDeviceDetected,
			previousPage,
			education,
			personalValues,
			formData,
			selectedValues,
			selectedSummaryValues,
			summaryIndexRemoved,
			passion,
			downloadTriggered,
			isSpecialUser,
			isLoggingOut,
			isLoggingIn,
			ambitionFormCompleted,
			lastPageIndex,
			matchOverlayShown,
			isOverlayVisible,
			editSidebarVisible,
			editSidebarSection,
			uniqueSortedExperiences,
			uniqueSortedEducations,
			summarySortedExperiences,
			summarySortedEducations,
			totalExperienceYears,
			areValuesShownOnSummary,
			areAmbitionsShownOnSummary,
			areSkillsShownOnSummary,
			arePassionsShownOnSummary,
			areEducationShownOnSummary,
			areExperiencesShownOnSummary,
			isSummaryShown,
			isAuthenticated,
			attempts,
			maxAttempts,
			maxLimitReached,
			showMatchOveraly,
			improveProfileVisible,
			checkLogin,
			setOverlayVisibility,
			setOverlayShown,
			logout,
			addNewEducation,
			saveEducation,
			updateEducation,
			removeEducation,
			addNewExperience,
			saveExperience,
			updateExperience,
			removeExperience,
			downloadCV,
			resetDownloadTrigger,
			savePersonalInfo,
			savePassion,
			setPreviousPage,
			completeAmbitionForm,
			toggleValue,
			toggleSummaryValue,
			setSelectedValues,
			loadPersonalValues,
			setStateAndInitialize,
			setMobileDevice,
			setState,
			setFetchedData,
			openSidebar,
			closeSidebar,
			updateSummary,
			updateRecordImage,
			toggleTypesVisiblityOnSummary,
			saveSelectedValuesVisibility,
			updateAmbitionVisibilities,
			saveSelectedValues,
			resetAllStates,
			isVideoPopupVisible,
			videoUrl,
			showVideoPopup,
			closeVideoPopup
		}
});