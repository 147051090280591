import { ref } from 'vue';

export function useGoogleMaps() {
  const googleApiLoaded = ref(false);
  const autocompleteService = ref();
  const geocoder = ref();

  const loadGoogleMapsApi = () => {
    return new Promise((resolve, reject) => {
      if (typeof google !== 'undefined') {
        googleApiLoaded.value = true;
        resolve(google);
        return;
      }

      const apiKey = import.meta.env.VITE_GOOGLE_KEY;

      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        googleApiLoaded.value = true;
        resolve(google);
      };
      script.onerror = () => reject(new Error('Failed to load Google Maps API'));

      document.head.appendChild(script);
    });
  };

  const initGoogleServices = async () => {
    if (!googleApiLoaded.value) {
      await loadGoogleMapsApi();
    }
    autocompleteService.value = new google.maps.places.AutocompleteService();
    geocoder.value = new google.maps.Geocoder();
  };

  const getAddressComponent = (components: any[], type: any) => {
    const component = components.find((comp) => comp.types.includes(type));
    return component ? component.long_name : '';
  };

  return {
    initGoogleServices,
    autocompleteService,
    geocoder,
    getAddressComponent,
  };
}
