import { ref } from "vue";
import { useGlobalStore } from "@/store/store";
import { storeToRefs } from "pinia";
import { usePopup } from "./usePopup";

const selectedImage = ref('');
const showCropper = ref(false);
const cropperRef = ref();
const croppedFile = ref();

export const useCropper = () => {
	const { openPopup } = usePopup();
	const { isAuthenticated } = storeToRefs(useGlobalStore());
	function triggerFileInput() {
		if(!isAuthenticated.value) {
			openPopup()
			return
		}
		const input = document.createElement("input");
		input.type = "file";
		input.accept = "image/*";
		input.onchange = (e: any) => {
			const file = e.target.files[0];
			handleFileSelect(file)
			if (file) {
			}
		};
		input.click();
	}

	function handleFileSelect(file: File) {
		if (file) {
			previewImage(file);
		}
	}

	function previewImage(file: File) {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = (e) => {
			selectedImage.value = e.target?.result as string;
			showCropper.value = true;
		};
	}

	function confirmCrop() {
		const cropper = cropperRef.value;
		if (!cropper) return;

		// Get the cropped image in a blob
		cropper.value.getCroppedCanvas().toBlob((blob: Blob) => {
			croppedFile.value = new File([blob], 'profile_cropped.png', { type: 'image/png' });
			showCropper.value = false;
		}, 'image/png');
	}

	function cancelCrop() {
		showCropper.value = false;
		selectedImage.value = '';
	}

	return {
		selectedImage,
		showCropper,
		cropperRef,
		croppedFile,
		triggerFileInput,
		handleFileSelect,
		previewImage,
		confirmCrop,
		cancelCrop,
	};
}

export default { useCropper };