// store/modules/profile.js

import apiClient from '@/axios';
import { nextTick, ref } from 'vue';
import { defineStore } from 'pinia';

// @ts-ignore
import profilePic from '@/assets/profile.png';
import { convertImageToBase64 } from './utils';

function enforceHTTPS(url: string | null) {
	if (!url) {
		console.error('No URL provided');
		return null;  // Return null if the URL is empty
	}

	try {
		const parsedUrl = new URL(url);
		if (parsedUrl.protocol === 'http:') {
			console.warn(`Upgrading insecure URL from HTTP to HTTPS: ${url}`);
			parsedUrl.protocol = 'https:';  // Enforce HTTPS
		}
		return parsedUrl.toString();
	} catch (e: any) {
		console.error('Invalid URL provided:', url, 'Error:', e.message);
		return null;  // Return null for invalid URLs
	}
}

const defaultProfilePicture = profilePic;

export const useProfileStore = defineStore('profile', () => {

	const profilePicture = ref(defaultProfilePicture) // Initialize with default
	const profilePictureUrl = ref(defaultProfilePicture)

	async function uploadProfilePicture(file: File, setProfilePicture: boolean = false) {
		
		const base64Image = await convertImageToBase64(file);

		const jsonPayload = {
			image: base64Image,
		};

		try {
			const response = await apiClient.post('/upload_image', jsonPayload, {});

			const imageId = response.data.imageId;
			if (!imageId || imageId.length !== 24) { // Assuming MongoDB ObjectId
				throw new Error('Invalid image ID returned from server');
			}

			// Request a signed URL from the backend
			const signedUrlResponse = await apiClient.get(`/generate-signed-url/${imageId}`);
			let signedUrl = signedUrlResponse.data.signedUrl;

			// Enforce HTTPS on signed URL
			if(!signedUrl.includes('localhost')) signedUrl = enforceHTTPS(signedUrl);

			if(setProfilePicture) {
				profilePicture.value = signedUrl as string;
				profilePictureUrl.value = signedUrl as string;
			}
	
		} catch (error) {
			console.error('Error uploading image:', error);
		}
	}

	async function fetchAndUploadProfilePicture(url: string, setProfilePicture?: boolean) {
		// Enforce HTTPS on the incoming URL
		url = enforceHTTPS(url) as string;
		if (url.includes('licdn.com/')) {
			try {
				const response = await fetch(url);

				if(response.status !== 200 && sessionStorage.getItem('tmpProfileImage')) {
					const url = sessionStorage.getItem('tmpProfileImage') as string
					await fetchAndUploadProfilePicture(url, true)
					/** Prevent infinite loop */
					sessionStorage.removeItem('tmpProfileImage')
				}
				const blob = await response.blob();
				const fileToUpload = new File([blob], 'profile.png', { type: 'image/png' });

				if(setProfilePicture) await uploadProfilePicture(fileToUpload, true);
				else await uploadProfilePicture(fileToUpload);
			} catch (error) {
				if (sessionStorage.getItem('tmpProfileImage')) {
					const url = sessionStorage.getItem('tmpProfileImage') as string
					return await fetchAndUploadProfilePicture(url, true)
				}
				console.error('Error fetching and uploading default profile picture:', error);
			}
		} else {
			try {
				// Fetch the image via the proxy endpoint
				const response = await apiClient.get('/proxy-image', {
					params: { url },
					responseType: 'blob',
				});

				const contentType = response.headers['content-type'] || 'image/jpeg';
				const file = new Blob([response.data], { type: contentType });
				const fileName = url.split('/').pop()?.split('?')[0] || 'profile.jpg';
				const fileToUpload = new File([file], fileName, { type: contentType });

				if(setProfilePicture) await uploadProfilePicture(fileToUpload, true);
				else await uploadProfilePicture(fileToUpload);
			} catch (error) {
				console.error('Error fetching and uploading image:', error);

				// Fallback to default image
				try {
					let response
					if (sessionStorage.getItem('tmpProfileImage')) {
						url = JSON.stringify(sessionStorage.getItem('tmpProfileImage'))
						response = await fetch(url)
					} else {
						response = await fetch(defaultProfilePicture);
					}
					const blob = await response.blob();
					const fileToUpload = new File([blob], 'profile.png', { type: 'image/png' });

					if(setProfilePicture) await uploadProfilePicture(fileToUpload, true);
					else await uploadProfilePicture(fileToUpload);
				} catch (fallbackError) {
					console.error('Error uploading fallback profile picture:', fallbackError);
				}
			}
		}
	}

	const loadProfileState = async() => {
		try {
			const response = await apiClient.get('/load_state');
			const { state, isSpecialUser, profilePictureUrl } = response.data;

			if (profilePictureUrl) {
				// Enforce HTTPS on profilePictureUrl
				const secureUrl = enforceHTTPS(profilePictureUrl);

				profilePictureUrl.value = secureUrl;
				profilePicture.value = secureUrl as string;
			}

			// Handle additional state and isSpecialUser logic as needed
		} catch (error) {
			console.error('Error loading profile state:', error);
		}
	}

	async function manageFallback(url: string) {
		url = enforceHTTPS(url) as string;
		
		if (!isValidURL(url)) {
			console.error('Invalid URL:', url);
			return null;
		}
		
		try {
			const response = await fetch(url);

			if(response.status === 200) {
				return url
			}

			if (sessionStorage.getItem('tmpProfileImage')) {
				const url = sessionStorage.getItem('tmpProfileImage') as string
				return await manageFallback(url)
			} else {
				return null
			}
			
		} catch (error) {
			console.error('Error fetching and uploading default profile picture:', error);
		}
	}

	function isValidURL(url: string): boolean {
    try {
        new URL(url); // Checks if the URL can be constructed
        return true;
    } catch (e) {
        return false;
    }
}

	return {
		profilePicture,
		profilePictureUrl,
		uploadProfilePicture,
		fetchAndUploadProfilePicture,
		loadProfileState,
		manageFallback
	}
})
