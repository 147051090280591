<template>
    <div v-if="visible" class="video-popup-overlay" @click.self="closePopup">
      <div class="video-popup-container">
        <button class="close-btn" @click="closePopup">
          <i class="fa-regular fa-x"></i>
        </button>
        <video controls autoplay muted>
          <source :src="videoUrl" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'VideoPopup',
    props: {
      videoUrl: {
        type: String,
        required: true,
      },
      visible: {
        type: Boolean,
        required: true,
      },
    },
    emits: ['close'],
    methods: {
      closePopup() {
        this.$emit('close');
      },
    },
  };
  </script>
  
  <style scoped>
  .video-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  
  .video-popup-container {
    background: #ffffff;
    border-radius: 10px;
    padding: 30px;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

  }
  
  video {
    max-width: 100%;
    max-height: 80vh;
    border-radius: 8px;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
    color: #7D8AA6;
  }
  
  .close-btn:hover {
    color: #0056b3;
  }
  </style>
  